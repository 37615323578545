import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const User = ({ theme ,mode}) => {
  const { palette } = theme;
  theme.palette.mode = mode;
  const firstname = localStorage.getItem('userfirstname');
  const lastname = localStorage.getItem('userlastname');
  const avatarUrl = '';

  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      {/* <Avatar alt={firstname} src={avatarUrl} /> */}
      <Typography
        variant="body1"
        style={{ marginLeft: 15, fontFamily: 'Audiowide, sans-serif' }}
      >
        {firstname} . {lastname}
      </Typography>

      {/* <KeyboardDoubleArrowRightIcon style={{ marginLeft: 8 }} /> */}
    </div>
  );
};

export default User;

import React, { useState } from 'react';
import { TextField } from '@mui/material';

const EditableTextField = ({ initialText, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleEditEnd = () => {
    setIsEditing(false);
    onSave(text); // Notify parent of the change
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return isEditing ? (
    <TextField
      value={text}
      onChange={handleChange}
      onBlur={handleEditEnd} // Save changes on blur
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleEditEnd(); // Save changes on Enter key
        }
      }} 
      autoFocus
    />
  ) : (
    <span onClick={handleEditStart} style={{ cursor: 'pointer' }}>
      {text}
    </span> 
  );
};

export default EditableTextField;

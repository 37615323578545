import React, { useState, useContext} from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ToggleColorMode from "./ToggleColorMode";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from "@mui/icons-material/Settings";
import PublishIcon from "@mui/icons-material/Publish";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { auth, storage } from "../authentication/Firebase";
import User from "./User";
import getLPTheme from "../../getLPTheme";
import { AppContext } from "../../AppContext.js";
import {
  ref,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

const AppAppBar = ({ mode, toggleColorMode }) => {
  const {
    inHome,
    setInHome,
    setOpen,
    edit,
    setEdit,
    data,
    setMessage,
    handleSnackClick,
    metadataRef,
    setMetadataOpen,
    selectedRef,
    setMetaDataRef,
    setData,
  } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const theme = getLPTheme();

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function updateMetadata(storage, reference, newKeyValuePairs) {
    // Construct a reference to the metadata JSON file
    const metadataRef = ref(storage, reference);
    try {
      const updatedMetadataString = JSON.stringify(newKeyValuePairs);
      await uploadString(metadataRef, updatedMetadataString, "raw", {
        contentType: "application/json",
      });

      setMessage("Metadata updated!");
      handleSnackClick();
    } catch (error) {
      setMessage("Error updating metadata!");
      handleSnackClick();
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        ("User signed out");
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };

  const setMultipleAttributes = (el, attributes) => {
    for (var attr in attributes) el.setAttribute(attr, attributes[attr]);
  };

  const toggleEdit = () => {
    //eslint-disable-next-line
    if (edit == true) {
      setEdit(false);
      setMetadataOpen(false);
      setMessage("Edit mode off!");
      handleSnackClick();
      Array.prototype.slice
        .call(document.getElementsByTagName("a-sphere"))
        .forEach(function (item) {
          item.remove();
        });
    } else if (selectedRef) {
      const folderPath = selectedRef.substring(0, selectedRef.lastIndexOf("/"));
      const metaDataPath =
        folderPath +
        "/" +
        folderPath.substring(folderPath.lastIndexOf("/") + 1) +
        "-metadata.json";
      setMetaDataRef(metaDataPath);
      getDownloadURL(ref(storage, metaDataPath))
        .then((url) => {
          // Use the URL to fetch the JSON data
          setMessage("Loading metadata!");
          handleSnackClick();
          return fetch(url);
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((jsonData) => {
          // Use jsonData as your JSON data
          setData(jsonData);
          for (var key in jsonData) {
            var sphereEl = document.createElement("a-sphere");
            if (sphereEl) {
              setMultipleAttributes(sphereEl, {
                radius: 10,
                position: jsonData[key].intersection,
                class: "clickable",
                color: "black",
                spheres: true,
                number: key,
              });
              document.getElementsByTagName("a-scene")[0].appendChild(sphereEl);
            }
          }
          setEdit(true);
          setMessage("Middle click mouse button to add metadata!");
          handleSnackClick();
        })
        .catch((error) => {
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
          setMessage("No Image Found");
          handleSnackClick();
          setOpen(false);
        });
    }
  };

  const goToHome = () => {
    setInHome(true);
    handleDrawerClose();
    handleMenuClose();
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
          padding: "0px",
        }}
      >
        <Container maxWidth="false">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              width: "100%",
              padding: "0px",
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-10px",
                px: 0,
              }}
            >
              <User theme={theme} mode={mode}/>

              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  marginLeft: "50px",
                  flexGrow: 1,
                }}
              >
                <Tooltip title="Go Home">
                  <IconButton onClick={goToHome}>
                  <HomeIcon/>
                  </IconButton>
                </Tooltip>
                {!inHome && (
                  <Tooltip title="Edit Metadata">
                  <IconButton onClick={toggleEdit}>
                    <ModeEditOutlineIcon color={edit?"warning":""} />
                  </IconButton>
                </Tooltip>
                )}
                 {edit && (
                  <Tooltip title="Submit Metadata">
                    <IconButton
                      className="Submit Metadata"
                      onClick={() => updateMetadata(storage, metadataRef, data)}
                    >
                      <PublishIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  marginLeft: "50px",
                }}
              >
               

                {/* <Tooltip title="Settings">
                  <IconButton
                    className="settingsButton"
                    onClick={handleMenuOpen}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip> */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>Item 1</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Item 2</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Item 3</MenuItem>
                </Menu>
                <Tooltip title="Logout">
                  <IconButton className="logoutButton" onClick={handleLogout}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;

// AppContext.js
import React, { createContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

// Create a context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  // Define shared variables and their setters
  const [inHome, setInHome] = useState(true);
  const [fs, setfs] = useState({});
  const [selectedRef, setSelectedRef] = useState(null);
  const [storageRef, setStorageRef] = useState(null);
  const [roomPath, setRoomPath] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorE1, setAnchorE1] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [metadataOpen, setMetadataOpen] = useState(false);
  const [keyValues, setKeyValues] = useState([{ key: "", value: "" }]);
  const [currentSphereNumber, setCurrentSphereNumber] = useState("0");
  const [metadataAnchorEl, setMetadataAnchorEl] = useState(null);
  const [metadataPlacement, setMetadataPlacement] = useState(null);
  const [metadataRef, setMetaDataRef] = useState("");
  const [data, setData] = useState({});
  const [isListening, setIsListening] = useState(false);
  const [currentlyRecording, setCurrentlyRecording] = useState(false);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [ffmpeg, setFfmpeg] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [dataArray, setDataArray] = useState(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [chunks, setChunks] = useState([]);
  const [skyLoading, setSkyLoading] = useState(true); // State to track if sky image is loading
  const [showLoader, setShowLoader] = useState(true); // State to control loader display
  const [confirmDialogText, setConfirmDialogText] = useState("");
  const [intersectedElTextValue, setIntersectedElTextValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Current page index

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackOpen(false);
    setMessage("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ConfirmDialog = ({ open, onClose }) => {
    const handleConfirm = () => {
      onClose(true);
    };
  
    const handleCancel = () => {
      onClose(false);
    };
  
    return (
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const useConfirmDialog = () => {
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);
  
    const confirm = () => {
      return new Promise((res) => {
        setOpen(true);
        setResolve(() => res);
      });
    };
  
    const handleClose = (result) => {
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };
  
    return {
      confirm,
      dialog: <ConfirmDialog open={open} onClose={handleClose} />,
    };
  };

  return (
    <AppContext.Provider
      value={{
        inHome,
        setInHome,
        selectedRef,
        setSelectedRef,
        open,
        setOpen,
        anchorE1,
        snackOpen,
        setSnackOpen,
        handleSnackClick,
        handleSnackClose,
        action,
        message,
        setMessage,
        setAnchorE1,
        edit,
        setEdit,
        data,
        setData,
        showForm,
        setShowForm,
        roomPath,
        setRoomPath,
        rooms,
        setRooms,
        selectedRoom,
        setSelectedRoom,
        currentSphereNumber,
        setCurrentSphereNumber,
        setfs,
        fs,
        metadataOpen,
        setMetadataOpen,
        keyValues,
        setKeyValues,
        metadataAnchorEl,
        setMetadataAnchorEl,
        metadataRef,
        setMetaDataRef,
        metadataPlacement,
        setMetadataPlacement,
        storageRef,
        setStorageRef,
        isListening,
        setIsListening,
        currentlyRecording,
        setCurrentlyRecording,
        ffmpegLoaded,
        setFfmpegLoaded,
        ffmpeg,
        setFfmpeg,
        audioContext,
        setAudioContext,
        analyser,
        setAnalyser,
        dataArray,
        setDataArray,
        animationFrameId,
        setAnimationFrameId,
        recorder,
        setRecorder,
        chunks,
        setChunks,
        skyLoading,
        setSkyLoading,
        showLoader,
        setShowLoader,
        confirmDialogText,
        setConfirmDialogText,
        useConfirmDialog,
        intersectedElTextValue, setIntersectedElTextValue,
        currentSphereNumber, setCurrentSphereNumber,
        currentPage, setCurrentPage
        
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { auth, store } from './Firebase';
import { doc, getDoc } from 'firebase/firestore';
import {userStorage, defaults} from '../constants';

const SignedInRoute = (props) => {
  const [signedIn, setSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleAuthChange = async (user) => {
      if (user) {
        try {
          const userProfileDoc = doc(store, userStorage.users, user.uid);
          const docSnapshot = await getDoc(userProfileDoc);

          const userProfile = docSnapshot.data();
          localStorage.setItem('userfirstname', userProfile.firstname);
          localStorage.setItem('userlastname', userProfile.lastname);
          localStorage.setItem('email', userProfile.email);

          setLoading(false);
          setSignedIn(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
          localStorage.setItem('userfirstname', defaults.user.firstname);
          localStorage.setItem('userlastname', defaults.user.lastname);
          setLoading(false);
          setSignedIn(true);
        }
      } else {
        localStorage.setItem('userfirstname', defaults.user.firstname);
        localStorage.setItem('userlastname', defaults.user.lastname);
        setLoading(false);
        setSignedIn(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthChange);

    return unsubscribe;
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return signedIn ? <Outlet /> : <Navigate to={props.redirect} />;
}

export default SignedInRoute;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import Homepage from "./components/homepage/Homepage";
import SignIn from "./components/signin/SignIn";
import SignUp from "./components/signup/SignUp";
import InteriaUI from "./components/landingpage/InteriaUi";
import QuestPage from "./components/questpage/questPage";
import SignedInRoute from "./components/authentication/SignedInRoute";
import PeerToPeerMessaging from "./components/mobilePage/mobile";
import NotFoundPage from "./404.js";


import "./styles/App.css";

function LoggedInUser() {
  const [isQuest, setIsQuest] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let details = navigator.userAgent;
    if (details.includes("OculusBrowser")) {
      setIsQuest(true);
      navigate("/customer/quest");
    }
  }, [navigate]);

  return (
    <div className="App">
      <center><h1>Welcome to Interia</h1></center>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/user" element={<LoggedInUser />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route element={<SignedInRoute redirect="/signin" />}>
          <Route path="/designer" element={<InteriaUI />} />
        </Route>
        <Route path="/customer/quest" element={<QuestPage />} />
        <Route element={<SignedInRoute redirect="/signin" />}>
          <Route path="/customer/mobile" element={<PeerToPeerMessaging />} />
        </Route>
        {/* 404 page route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";
import { Box, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MobileAppAppBar from "./mobileAppBar.js";
import getLPTheme from "../../getLPTheme.js";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { auth } from "../authentication/Firebase";
import { URL, MQTTOptions } from "../constants.js";
import axios from "axios";
import mqtt from "mqtt";
import Button from "@mui/material/Button";

const PeerToPeerMessaging = () => {
  const [connectStatus, setConnectStatus] = useState(false);
  const [mode, setMode] = useState("dark");
  const LPtheme = createTheme(getLPTheme(mode));

  const [publicIP, setPublicIP] = useState("");
  const [privateIP, setPrivateIP] = useState("");
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [messages, setMessages] = useState([]);
  const [client, setClient] = useState(null);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // Fetch the public IP address
  const fetchPublicIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setPublicIP(response.data.ip);
    } catch (error) {
      console.error("Failed to fetch public IP address:", error);
    }
  };

  // Fetch the private IP address
  const fetchPrivateIpAddress = () => {
    const rtc = new RTCPeerConnection({ iceServers: [] });
    rtc.createDataChannel("");
    rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
    rtc.onicecandidate = (event) => {
      if (event && event.candidate && event.candidate.candidate) {
        const candidate = event.candidate.candidate;
        console.log("Candidate:", candidate);
        const uniqueAdress = candidate.split(" ")[4];
        console.log("uniqueAdress:", uniqueAdress);
        if (uniqueAdress) {
          setPrivateIP(uniqueAdress);
          rtc.close();
        }
      }
    };
  };
  useEffect(() => {
    fetchPublicIpAddress();
    fetchPrivateIpAddress();
  }, []);

  const timeoutID = useRef(null);

  useEffect(() => {
    if (!publicIP || !privateIP) return;

    const newClient = mqtt.connect(URL.MQTTServer, MQTTOptions);
    setClient(newClient);

    newClient.on("connect", () => {
      console.log("Connected to MQTT broker via WebSocket");
      const wildcardTopic = `${publicIP}`; // Listen to all topics starting with public IP

      newClient.subscribe(wildcardTopic, { qos: 1 }, (err) => {
        if (err) {
          console.error(
            "Failed to subscribe to wildcard topic:",
            wildcardTopic,
            err
          );
        } else {
          console.log("Subscribed to wildcard topic:", wildcardTopic);
        }
      });
    });

    newClient.on("message", (topic, message) => {
      console.log("Received message:", message.toString(), "on topic:", topic);
      const data = JSON.parse(message.toString());
      if (topic === publicIP) {
        if (data.stop == privateIP) {
          setConnectStatus(true);
          console.log("Subscribed to topic:", publicIP + "/" + privateIP);
        }
      }
      if(data.message === "SimutopiaMobileReload"){
        window.location.reload();
      }
    });

    newClient.on("error", (err) => {
      console.error("Connection error:", err);
    });

    if (!connectStatus) {
      const message = {
        token: localStorage.getItem("token"),
        username: auth.currentUser.email,
        privateIP: privateIP,
      };

      timeoutID.current = setInterval(() => {
        if (publicIP && privateIP && newClient) {
          console.log("Sending message to:", publicIP + "/" + privateIP);
          newClient.publish(
            publicIP + "/" + privateIP,
            JSON.stringify(message),
            { qos: 1 },
            (err) => {
              if (err) {
                console.error("Failed to publish message", err);
              } else {
                console.log(
                  "Message published to topic:",
                  publicIP + "/" + privateIP
                );
              }
            }
          );
        }
      }, 5000);
    } else if (connectStatus) {
      console.log("Connected");
      clearInterval(timeoutID.current);
      newClient.subscribe(publicIP + "/" + privateIP, { qos: 1 }, (err) => {
        if (err) {
          console.error(
            "Failed to subscribe to topic:",
            publicIP + "/" + privateIP,
            err
          );
        } else {
          console.log("Subscribed to topic:", publicIP + "/" + privateIP);
        }
      });
    }

    return () => {
      if (timeoutID.current) {
        clearInterval(timeoutID.current);
      }
      if (newClient) {
        newClient.end();
      }
    };
  }, [publicIP, privateIP, selectedTopic, connectStatus, auth]);

  // const handleTopicClick = (topic) => {
  //   setSelectedTopic(topic);

  //   const message = `I am trying to connect with you:`+localStorage.getItem("token");
  //   if (client) {
  //     client.publish(topic, message, { qos: 1 }, (err) => {
  //       if (err) {
  //         console.error("Failed to publish message", err);
  //       } else {
  //         console.log("Message published to topic:", topic);
  //         // After publishing the message, unsubscribe from the wildcard topic
  //         const wildcardTopic = `${publicIP}/#`;
  //         client.unsubscribe(wildcardTopic, (err) => {
  //           if (err) {
  //             console.error(
  //               "Failed to unsubscribe from wildcard topic:",
  //               wildcardTopic,
  //               err
  //             );
  //           } else {
  //             console.log("Unsubscribed from wildcard topic:", wildcardTopic);
  //           }
  //         });

  //         // Subscribe to the specific topic for receiving replies
  //         client.subscribe(topic, { qos: 1 }, (err) => {
  //           if (err) {
  //             console.error("Failed to subscribe to topic:", topic, err);
  //           } else {
  //             console.log("Subscribed to topic:", topic);
  //           }
  //         });
  //       }
  //     });
  //   }

  //   setMessages([]); // Clear previous messages when a new topic is selected
  // };

  function generateToken() {
    const uid = auth.currentUser.uid;
    fetch(URL.authServer + "/generate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid: uid }),
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("token", data.token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    generateToken();
  }, []);

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <MobileAppAppBar
        theme={LPtheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
      />
      <Box sx={{ flexGrow: 1, marginTop: "100px" }}>
        <Stack
          spacing={3}
          sx={{
            fontSize: "large",
            alignItems: "center",
            justifyContent: "left",
          }}
          maxWidth={320}
        >
          <Item sx={{ fontSize: "large" }}>
            Connection Status :
            <span
              style={{
                color: connectStatus ? "green" : "red",
                marginLeft: "10px",
              }}
            >
              {connectStatus ? "Connected" : "Disconnected"}
            </span>
          </Item>
          {/* {allTopics.map((topic) => (
            <Button
              variant="contained"
              key={topic}
              sx={{ fontSize: "large" }}
              onClick={() => handleTopicClick(topic)}
            >
              {topic}
            </Button>
          ))} */}
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default PeerToPeerMessaging;

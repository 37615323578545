import React, { useContext, useMemo } from "react";
import getLPTheme from "../../getLPTheme";
import { AppContext } from "../../AppContext";

export default function RoomBar({ mode }) {
  const theme = getLPTheme();
  theme.palette.mode = mode;

  const {
    roomPath,
    setRoomPath,
    rooms,
    selectedRoom,
    fs,
    edit,
    setConfirmDialogText,
    useConfirmDialog,
    setEdit,
  } = useContext(AppContext);

  const { confirm, dialog } = useConfirmDialog();

  const roomBarStyles = useMemo(
    () => ({
      display: "flex",
      flexDirection: "row",
      margin: "auto",
      width: "90vw",
      position: "fixed",
      overflowX: "scroll",
      overflowY: "hidden",
      bottom: 20,
      left: 0,
      right: 0,
      zIndex: 1200,
      scrollbarWidth: "thin",
      scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0)",
      "&::WebkitScrollbar": {
        width: "0.4em",
      },
    }),
    []
  );

  function nThIndexOf(str, char, n) {
    var count = 0;
    for (var i = 0; i < str.length; i++) {
      if (str[i] === char) {
        count++;
        if (count === n) {
          return i; // return the index when the fourth occurrence is found
        }
      }
    }
    return -1; // return -1 if the character doesn't occur four times
  }

  return (
    <div style={{ width: "80vw" }}>
      {rooms ? (
        <div style={roomBarStyles}>
          {rooms.map((room) => {
            let roomPNGExists = false;
            if (roomPath != null) {
              let currentProjName = roomPath.substring(
                nThIndexOf(roomPath, "/", 4) + 1,
                nThIndexOf(roomPath, "/", 5)
              );
              if (
                fs[currentProjName][room] &&
                fs[currentProjName][room].hasOwnProperty(room + ".png")
              ) {
                roomPNGExists = true;
              }
            }

            if (roomPNGExists) {
              const roomStyles = {
                fontWeight: "bold",
                margin: "1em",
                width: "100%",
                
                backgroundColor:
                  room === selectedRoom
                    ? theme.palette.mode === "light"
                      ? "rgba(255, 255, 255, 0.5)"
                      : "rgba(0, 0, 0, 0.5)"
                    : theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.1)"
                    : "rgba(0, 0, 0, 0.1)",
                transform: room === selectedRoom ? "scaleY(1.2)" : "scale(1)",
                backdropFilter: "blur(10px)",
                borderRadius: "7px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
                padding: "0.5em",
                textAlign: "center",
                cursor: "pointer",
                // color:
                //   theme.palette.mode === "light"
                //     ? theme.palette.primary.main
                //     : "#000000",
              };
              return (
                <p
                  style={roomStyles}
                  key={room}
                  onClick={async () => {
                    if (room === selectedRoom) return;
                    if (edit) {
                      setConfirmDialogText(
                        "Are you sure you change the room? All data changes will be lost."
                      );
                      const result = await confirm();
                      if (!result) {
                        return;
                      }
                    }
                    setEdit(false);
                    setRoomPath(
                      roomPath.substring(0, roomPath.lastIndexOf("/")) +
                        "/" +
                        room
                    );
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.overflow = "visible";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.overflow = "hidden";
                  }}
                >
                  {room}
                </p>
              );
            } else {
              return null;
            }
          })}
        </div>
      ) : null}
      {dialog}
    </div>
  );
}

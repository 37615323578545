import React, { useState, useEffect, useRef } from "react";
import "aframe";
import interia_lobby from "../../icons/interia_lobby.jpeg";
import { AppContext } from "../../AppContext";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { listAll, getDownloadURL } from "firebase/storage";
import { getDoc, doc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { store, storage } from "../authentication/Firebase";
import { userStorage, witAIToken } from "../constants";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { URL, MQTTOptions } from "../constants";
import mqtt from "mqtt";
import interia_logo_no_BG from "../../icons/interiaLogo.svg";
import recording from "./recording.png";

let ffmpeg;
let audioContext;
let analyser;
let dataArray;
let animationFrameId;
let recorder;
let chunks = [];
const VOLUME_THRESHOLD = 0; // Define the threshold as a separate variable
let source = null;

function QuestPage() {
  const [data, setData] = useState(null);
  const baseURL = URL.baseURL;
  const [sharedProjectsCustomer, setSharedProjectsCustomer] = useState({});
  const skyRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const [connectStatus, setConnectStatus] = useState(false);
  const [text, setText] = useState(
    "Please Log in to your phone and make sure the phone and this device are on the same network."
  );
  const [designers, setDesigners] = useState(null);
  const [projects, setProjects] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [currentDesigner, setCurrentDesigner] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [previousDisplaySectionValue, setPreviousDisplaySectionValue] =
    useState([]);
  const [previousTextValue, setPreviousTextValue] = useState(null);
  const menuShowTime = 10000;
  const [currentSection, setCurrentSection] = useState(null);
  const [displaySection, setDisplaySection] = useState([]);
  const [xPosition, setXPosition] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const timeoutRef = useRef(null);

  //import from appcontext
  const {
    isListening,
    setIsListening,
    ffmpegLoaded,
    setFfmpegLoaded,
    currentlyRecording,
    setCurrentlyRecording,
    setSkyLoading,
    setShowLoader,
    skyLoading,
    intersectedElTextValue,
    setIntersectedElTextValue,
    currentSphereNumber,
    setCurrentSphereNumber,
    currentPage,
    setCurrentPage,
  } = React.useContext(AppContext);

  const showMenuTemporarily = () => {
    console.log("showing menu");
    setShowMenu(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      console.log("hiding menu");
      setShowMenu(false);
      timeoutRef.current = null;
    }, menuShowTime);
  };

  const [consoleText, setConsoleText] = useState("");

  const handleSkyLoaded = () => {
    setSkyLoading(false); // Update state when sky image has finished loading
    setShowLoader(false); // Hide loader when sky image has finished loading
  };

  useEffect(() => {
    const skyElement = skyRef.current;
    if (skyElement) {
      skyElement.addEventListener("materialtextureloaded", handleSkyLoaded);

      return () => {
        skyElement.removeEventListener(
          "materialtextureloaded",
          handleSkyLoaded
        );
      };
    }
  }, []);

  //Currently works when reloaded but not when the page is loaded for the first time
  // useEffect(() => {
  //   const enterVR = async () => {
  //     try {
  //       const sceneEl = sceneRef.current;
  //       // Check if the scene element is available and has the enterVR method
  //       if (sceneEl && sceneEl.enterVR) {
  //         // Enter VR mode
  //         await sceneEl.enterVR();
  //       }
  //     } catch (error) {
  //       console.error('Failed to enter VR mode:', error);
  //       // Handle the error (e.g., show a user-friendly message or fallback UI)
  //     }
  //   };

  //   enterVR();
  // });

  function sanitizeKeys(obj) {
    if (obj !== Object(obj) || Array.isArray(obj)) return obj;

    const sanitizedObj = {};
    Object.keys(obj).forEach((key) => {
      const sanitizedKey = key.toLowerCase().replace(/[^a-z]/g, "");
      sanitizedObj[sanitizedKey] = sanitizeKeys(obj[key]);
    });
    return sanitizedObj;
  }

  useEffect(() => {
    localStorage.setItem(
      "sharedProjectsCustomer",
      JSON.stringify(sanitizeKeys(sharedProjectsCustomer))
    );
    console.log(
      "local sharedProjectsCustomer in use effect",
      JSON.parse(localStorage.getItem("sharedProjectsCustomer"))
    );
    if (Object.keys(sharedProjectsCustomer).length > 0) {
      setText(
        // `Please say "Interia, select designer ${
        //   Object.keys(sharedProjectsCustomer)[0]
        // }" to show that designer's projects`
        "Please press the right trigger and say the Designer name to select the Designer"
      );
      setDisplaySection(Object.keys(sharedProjectsCustomer));
      setDesigners(Object.keys(sharedProjectsCustomer));
      setCurrentSection("designer");
      setMetadata(null);
      setCurrentSphereNumber(0);
    }
  }, [sharedProjectsCustomer]);

  useEffect(() => {
    if (currentDesigner && sharedProjectsCustomer) {
      if (sharedProjectsCustomer.hasOwnProperty(currentDesigner)) {
        setText(
          // `Please say "Interia, switch to ${
          //   Object.keys(sharedProjectsCustomer[currentDesigner])[0]
          // }" to show that project's rooms`
          "Please press the right trigger and say the Project name to select the Project"
        );
        setProjects(Object.keys(sharedProjectsCustomer[currentDesigner]));
        setDisplaySection(Object.keys(sharedProjectsCustomer[currentDesigner]));
        setCurrentSection("project");
        localStorage.setItem("localCurrentDesigner", currentDesigner);
        setMetadata(null);
        setCurrentSphereNumber(0);
      } else {
        console.log("No such designer exists");
        showMenuTemporarily();
      }
    }
  }, [currentDesigner]);

  useEffect(() => {
    if (currentProject && currentDesigner && sharedProjectsCustomer) {
      if (
        sharedProjectsCustomer[currentDesigner].hasOwnProperty(currentProject)
      ) {
        setText(
          // `Please say "Interia, go to ${
          //   Object.keys(
          //     sharedProjectsCustomer[currentDesigner][currentProject]
          //   )[0]
          // }" to show that room`
          "Please press the right trigger and say the Room name to select the Room"
        );
        setRooms(
          Object.keys(sharedProjectsCustomer[currentDesigner][currentProject])
        );
        console.log(
          sharedProjectsCustomer[currentDesigner][currentProject],
          sharedProjectsCustomer
        );
        setDisplaySection(
          Object.keys(sharedProjectsCustomer[currentDesigner][currentProject])
        );
        setMetadata(null);
        setCurrentSphereNumber(0);
        setCurrentSection("room");
        localStorage.setItem("localCurrentProject", currentProject);
      } else {
        console.log("No such project exists");
        showMenuTemporarily();
      }
    }
  }, [currentProject]);

  function loadImage(selectedRef) {
    Array.prototype.slice
      .call(document.getElementsByTagName("a-sphere"))
      .forEach(function (item) {
        item.remove();
      });
    getDownloadURL(ref(storage, selectedRef))
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.open("GET", url);
        xhr.send();
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", url);
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
      })
      .catch(function (error) {
        // Handle any errors
        TTS("Ask your designer to upload the image for this room!");
        console.error("Error fetching file:", error);
        const newSky = document.createElement("a-sky");
        newSky.setAttribute("class", "clickable");
        newSky.setAttribute("src", interia_lobby);
        newSky.setAttribute("rotation", "0 -90 0");
        // Replace the existing <a-sky> element with the new one
        sceneRef.current.replaceChild(newSky, skyRef.current);
        skyRef.current = newSky;
        setData(null);
        localStorage.setItem("data", null);
        setMetadata(null);
        setCurrentSphereNumber(0);
      });
    const folderPath = selectedRef.substring(0, selectedRef.lastIndexOf("/"));
    const metaDataPath =
      folderPath +
      "/" +
      folderPath.substring(folderPath.lastIndexOf("/") + 1) +
      "-metadata.json";
    getDownloadURL(ref(storage, metaDataPath))
      .then((url) => {
        // Use the URL to fetch the JSON data
        return fetch(url);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        // Use jsonData as your JSON data
        setData(jsonData);
        localStorage.setItem("data", JSON.stringify(jsonData));
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  }

  useEffect(() => {
    if (
      currentRoom &&
      currentDesigner &&
      currentProject &&
      sharedProjectsCustomer
    ) {
      if (
        sharedProjectsCustomer[currentDesigner][currentProject].hasOwnProperty(
          currentRoom
        )
      ) {
        setText(
          `Please say "Interia, show data number ___" to show metadata of that number`
        );
        loadImage(
          sharedProjectsCustomer[currentDesigner][currentProject][currentRoom]
        );
        setMetadata(null);
        setCurrentSphereNumber(0);
      }
    } else {
      console.log("No such room exists");
      showMenuTemporarily();
    }
  }, [currentRoom]);

  useEffect(() => {
    if (displaySection) {
      localStorage.setItem(
        "localDisplaySection",
        JSON.stringify(displaySection)
      );
      setXPosition(-2 * displaySection.length);
      showMenuTemporarily();
    }
  }, [displaySection]);

  function getSharedProjectsCustomer(email) {
    const sharedProjectsCustomerRef = doc(
      store,
      userStorage.sharedProjectsCustomer,
      email
    );
    getDoc(sharedProjectsCustomerRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        console.log("docsnap.data exists!");
        let editedData = docSnap.data();
        var requiredDS = await DS(editedData);
        // setSharedProjectsCustomer(requiredDS);
      }
    });
  }

  const setMultipleAttributes = (el, attributes) => {
    for (var attr in attributes) {
      el.setAttribute(attr, attributes[attr]);
    }
  };

  // function showSpheres(data) {
  //   const camera = document.querySelector('[camera]');
  //   const cameraPosition = new window.THREE.Vector3();
  //   camera.object3D.getWorldPosition(cameraPosition);

  //   for (var key in data) {
  //     if (isNaN(key)) {
  //       continue;
  //     }

  //     var textEl = document.createElement("a-text");
  //     if (textEl) {
  //       console.log("key", key);

  //       setMultipleAttributes(textEl, {
  //         position: data[key].intersection,
  //         spheres: true,
  //         value: key,
  //         align: "center",
  //         color: "black",
  //         width: 300,
  //         opacity: 1,
  //       });

  //       // Set the position of the text element
  //       const textPosition = new window.THREE.Vector3(data[key].intersection.x, data[key].intersection.y, data[key].intersection.z);
  //       textEl.object3D.position.copy(textPosition);

  //       // Use lookAt to orient the text element towards the camera
  //       textEl.object3D.lookAt(cameraPosition);

  //       // Optional: adjust the text position slightly if needed
  //       textEl.object3D.translateZ(1); // Adjust the value as needed

  //       if (sceneRef.current) {
  //         sceneRef.current.appendChild(textEl);
  //       }
  //     }
  //   }
  // }

  // function removeSpheres() {
  //   var spheres = document.querySelectorAll("a-text[spheres]");
  //   spheres.forEach((sphere) => {
  //     sphere.parentNode.removeChild(sphere);
  //   });
  // }

  //test starts
  function showSpheres(data) {
    const camera = document.querySelector("[camera]");
    const cameraPosition = new window.THREE.Vector3();
    camera.object3D.getWorldPosition(cameraPosition);

    for (var key in data) {
      if (isNaN(key)) {
        continue;
      }

      var textEl = document.createElement("a-text");
      // var planeEl = document.createElement("a-plane");
      // if (textEl && planeEl) {
      if (textEl) {
        console.log("key", key);
        console.log("currentSphereNumber", currentSphereNumber);

        setMultipleAttributes(textEl, {
          position: data[key].intersection,
          spheres: true,
          value: key,
          align: "center",
          color: "white",
          width: 300,
          scale: "2 2 2",
          class: "metadata",
        });

        // setMultipleAttributes(planeEl, {
        //   position: data[key].intersection,
        //   width: 10, // Adjust the width to be slightly larger than the text
        //   height: 10, // Adjust the height as needed
        //   color: "black",
        //   spheres: true,
        // });

        // Calculate direction vector from camera to intersection
        const intersectionPosition = new window.THREE.Vector3(
          data[key].intersection.x,
          data[key].intersection.y,
          data[key].intersection.z
        );
        const direction = new window.THREE.Vector3()
          .subVectors(intersectionPosition, cameraPosition)
          .normalize();

        // Move text and plane slightly inward toward the user
        const inwardDistanceText = -40; // Adjust the value as needed
        const inwardDistancePlane = -10; // Adjust the value as needed
        const inwardPositionText = intersectionPosition
          .clone()
          .add(direction.clone().multiplyScalar(inwardDistanceText));
        const inwardPositionPlane = intersectionPosition
          .clone()
          .add(direction.clone().multiplyScalar(inwardDistancePlane));

        // Set positions of text and plane elements
        textEl.object3D.position.copy(inwardPositionText);
        // planeEl.object3D.position.copy(inwardPositionPlane);

        // Use lookAt to orient the text and plane elements towards the camera
        textEl.object3D.lookAt(cameraPosition);
        // planeEl.object3D.lookAt(cameraPosition);

        if (sceneRef.current) {
          sceneRef.current.appendChild(textEl);
          // sceneRef.current.appendChild(planeEl);
        }
      }
    }
  }

  function removeSpheres() {
    var elements = document.querySelectorAll(
      "a-text[spheres], a-plane[spheres]"
    );
    elements.forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  useEffect(() => {
    if (data && data[currentSphereNumber]) {
      //change that sphere color
      console.log("trying to change the color of number", currentSphereNumber);
      var selectedSphere = document.querySelector(
        `a-text[value="${currentSphereNumber}"]`
      );
      console.log("selectedSphere", selectedSphere);
      //change  all spheres color to white
      var spheres = document.querySelectorAll("a-text[spheres]");
      spheres.forEach((sphere) => {
        sphere.setAttribute("color", "white");
      });
      if (selectedSphere) {
        console.log("selectedSphere", selectedSphere);
        selectedSphere.setAttribute("color", "red");
      }
    }
  }, [currentSphereNumber]);

  function clearSelectedSphereWithNumber(number) {
    if (data && data[number]) {
      //change that sphere color
      console.log("trying to change the color of number", number);
      var selectedSphere = document.querySelector(`a-text[value="${number}"]`);
      console.log("selectedSphere", selectedSphere);
      if (selectedSphere) {
        console.log("selectedSphere", selectedSphere);
        selectedSphere.setAttribute("color", "white");
      }
    }
  }
  //test ends

  function formatMetadata(data) {
    var met = "";
    for (var key in data) {
      if (key !== "intersection") {
        met += key + ": " + data[key] + "\n";
      }
    }
    return met;
  }

  useEffect(() => {
    if (data && data[currentSphereNumber]) {
      console.log("data[currentSphereNumber]", data[currentSphereNumber]);
      console.log("currentSphereNumber", currentSphereNumber);
      var met = formatMetadata(data[currentSphereNumber]);
      setMetadata(met);
      console.log(met);
    }
  }, [currentSphereNumber, data]);

  useEffect(() => {
    if (data) {
      showSpheres(data);
    }
    return () => {
      removeSpheres();
    };
  }, [data]);

  //mqtt code starts

  const [publicIP, setPublicIP] = useState("");
  const [privateIP, setPrivateIP] = useState("");
  const [client, setClient] = useState(null);
  const [subscribedTopics, setSubscribedTopics] = useState(new Set());
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    localStorage.setItem("subscribeTopics", JSON.stringify(subscribedTopics));
    if (currentIndex == subscribedTopics.length) {
      setCurrentIndex(0);
    }
  }, [currentIndex, subscribedTopics]);
  // Fetch the public IP address
  const fetchPublicIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setPublicIP(response.data.ip);
      console.log("Public IP address:", response.data.ip);
    } catch (error) {
      console.error("Failed to fetch public IP address:", error);
    }
  };

  // Fetch the private IP address
  const fetchPrivateIpAddress = () => {
    const rtc = new RTCPeerConnection({ iceServers: [] });
    rtc.createDataChannel("");
    rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
    rtc.onicecandidate = (event) => {
      if (event && event.candidate && event.candidate.candidate) {
        const candidate = event.candidate.candidate;
        console.log("ICE candidate:", candidate);
        const uniqueAdress = candidate.split(" ")[4];
        console.log("Unique address:", uniqueAdress);
        //remove initial space
        if (candidate) {
          setPrivateIP(uniqueAdress);
          rtc.close();
        }
      }
    };
  };
  useEffect(() => {
    fetchPublicIpAddress();
    fetchPrivateIpAddress();
  }, []);

  useEffect(() => {
    if (!publicIP || !privateIP) return;

    const newClient = mqtt.connect(URL.MQTTServer, MQTTOptions);
    const topic = `${publicIP}`;

    setClient(newClient);

    newClient.on("connect", () => {
      console.log("Connected to MQTT broker via WebSocket");
      newClient.subscribe(topic + "/#", { qos: 1 }, (err) => {
        if (err) {
          console.error("Failed to subscribe to topic:", topic, err);
        } else {
          console.log("Subscribed to topic:", topic);
        }
      });
    });

    newClient.on("message", (topic, message) => {
      console.log("Received message:", message.toString(), "on topic:", topic);
      // Check for the specific message and extract the code
      const messageJSON = JSON.parse(message.toString());
      const { token, username, privateIP } = messageJSON;
      // Check if received data is already in subscribedTopics
      let found = false;
      subscribedTopics.forEach((element) => {
        if (element.username === username) {
          found = true;
        }
      });
      if (!found) {
        setSubscribedTopics((prevTopics) => [
          ...prevTopics,
          { token, username, privateIP },
        ]);
      }
    });

    newClient.on("error", (err) => {
      console.log("Connection error:", err);
    });

    if (connectStatus && subscribedTopics.length > 0) {
      console.log("subscribedTopics", subscribedTopics);
      TTS("Connection established");
      //sign in with custom token
      console.log("subscribedTopics", subscribedTopics);
      var localSubscribedTopics = JSON.parse(
        localStorage.getItem("subscribeTopics")
      );
      const auth = getAuth();
      signInWithCustomToken(auth, localSubscribedTopics[currentIndex].token)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;
          getSharedProjectsCustomer(user.email);
        })
        .catch((error) => {
          console.log("error occured:::::::", error);
          //window.location.reload();
        });

      newClient.publish(
        publicIP,
        JSON.stringify({
          stop: subscribedTopics[currentIndex].privateIP,
        }),
        { qos: 1 },
        (err) => {
          if (err) {
            console.error("Failed to publish message:", err);
          } else {
            console.log(
              "Published message:",
              subscribedTopics[currentIndex].username,
              "on topic:",
              publicIP
            );
          }
        }
      );
      console.log(subscribedTopics[currentIndex].privateIP);
      // Handle beforeunload to publish a message
      const handleBeforeUnload = (event) => {
        try {
          newClient.publish(
            `${publicIP}/${subscribedTopics[currentIndex].privateIP}`, // Adjust the topic as needed
            JSON.stringify({ message: "SimutopiaMobileReload" }), // Customize the message as needed
            { qos: 1 },
            (err) => {
              if (err) {
                console.error("Failed to publish before unload message:", err);
              } else {
                console.log("Published before unload message");
              }
            }
          );
        } catch (err) {
          console.log("error in handleBeforeUnload", err);
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      newClient.subscribe(
        topic + "/" + subscribedTopics[currentIndex].privateIP,
        (err) => {
          if (err) {
            console.error(
              "Failed to subscribe to topic:",
              subscribedTopics[currentIndex].privateIP,
              err
            );
          } else {
            console.log(
              "Subscribed to topic:",
              subscribedTopics[currentIndex].privateIP
            );
          }
        }
      );
      newClient.unsubscribe(topic + "/#", (err) => {
        if (err) {
          console.error("Failed to unsubscribe from topic:", topic, err);
        } else {
          console.log("Unsubscribed from topic:", topic);
        }
      });
    }

    // Clean up
    return () => {
      if (newClient) {
        newClient.end();
      }
    };
  }, [publicIP, privateIP, connectStatus, subscribedTopics]);

  //TTS & STT starts
  const TTS = async (text) => {
    try {
      // Send POST request to Wit.ai API
      console.log("text", text);
      console.log("witAIToken", witAIToken);

      const response = await axios.post(
        "https://api.wit.ai/synthesize?v=20240304",
        { q: text, voice: "Rebecca" },
        {
          headers: {
            Authorization: "Bearer " + witAIToken, // Bearer token for authorization
            "Content-Type": "application/json",
            Accept: "audio/pcm16", // Correct MIME type for the request
          },
          responseType: "arraybuffer", // We need the response as a raw binary stream
        }
      );
      // Step 1: Create an AudioContext
      const audioContext = new window.AudioContext();
      // Step 2: Convert PCM16 Data to Float32
      function convertPCM16ToFloat32(buffer) {
        const dataView = new DataView(buffer);
        const float32Array = new Float32Array(buffer.byteLength / 2);
        for (let i = 0; i < float32Array.length; i++) {
          const int16 = dataView.getInt16(i * 2, true); // little-endian
          float32Array[i] = int16 / 32768; // convert to float32
        }
        return float32Array;
      }
      const sampleRate = 23500; // adjust to your audio data's sample rate
      const numberOfChannels = 1; // adjust to your audio data's number of channels
      const audioDataFloat32 = convertPCM16ToFloat32(response.data);
      const audioBuffer = audioContext.createBuffer(
        numberOfChannels,
        audioDataFloat32.length,
        sampleRate
      );
      audioBuffer.copyToChannel(audioDataFloat32, 0);
      if (source != null) source.stop();
      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0);

      console.log("Playing audio", text);
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  const loadFFmpeg = async () => {
    if (ffmpegLoaded == false) {
      console.log("loading ffmpeg");

      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
      ffmpeg = new FFmpeg();
      ffmpeg.on("log", ({ message }) => {
        // console.log(message);
      });
      ffmpeg
        .load({
          coreURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        })
        .then(() => {
          console.log("FFmpeg loaded");
          setFfmpegLoaded(true);
        });
    }
  };

  useEffect(() => {
    loadFFmpeg();
  }, []);

  if (!window.AFRAME.components["right-controllor"]) {
    window.AFRAME.registerComponent("right-controllor", {
      init: function () {
        this.el.addEventListener("triggertouchstart", (e) => {
          setIsListening(true);
          // startMonitoring();
        });
        this.el.addEventListener("triggertouchend", (e) => {
          setIsListening(false);
          // stopMonitoring();
        });
        this.el.addEventListener("gripdown", (e) => {
          const intersectedEls = e.target.components.raycaster.intersectedEls;
          if (intersectedEls.length > 0) {
            const intersectedEl = intersectedEls[0];
            if (intersectedEl.classList.contains("menu")) {
              const intersectedElTextValue = intersectedEl
                .getElementsByTagName("a-text")[0]
                .getAttribute("value");
              setIntersectedElTextValue(intersectedElTextValue);
            } else if (intersectedEl.classList.contains("metadata")) {
              const currentSphereNumber = intersectedEl.getAttribute("value");
              setCurrentSphereNumber(currentSphereNumber);
              TTS("Showing information on Number " + currentSphereNumber);
            } else if (intersectedEl.classList.contains("navigate")) {
              const intersectedElTextValue = intersectedEl
                .getElementsByTagName("a-text")[0]
                .getAttribute("value");
              if (intersectedElTextValue == "Next") {
                handleNext();
              }

              if (intersectedElTextValue == "Previous") {
                handlePrevious();
              }
            }
          }
        });
      },
    });
  }

  useEffect(() => {
    if (intersectedElTextValue) {
      console.log("intersectedElTextValue", intersectedElTextValue);

      if (currentSection == "designer") {
        chooseDesigner(intersectedElTextValue);
      } else if (currentSection == "project") {
        switchProject(intersectedElTextValue);
      } else if (currentSection == "room") {
        gotoRoom(intersectedElTextValue);
      }
    }
  }, [intersectedElTextValue]);

  useEffect(() => {
    if (isListening == true) {
      startMonitoring();
    } else if (isListening == false) {
      stopMonitoring();
    }
  }, [isListening]);

  const startMonitoring = async () => {
    if (currentlyRecording || !ffmpegLoaded) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      chunks = [];
      recorder = new MediaRecorder(stream);

      recorder.onstart = () => {
        console.log("Recording started");
        setCurrentlyRecording(true);
      };

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = async () => {
        console.log("Recording stopped");
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        console.log(audioBlob);
        try {
          if (audioBlob.size == 0) {
            setCurrentlyRecording(false);
            setIsListening(false);
            return;
          }
          await convertWebmToMp3(audioBlob);
        } catch (err) {
          console.error(err.message);
        } finally {
          chunks = [];
          setCurrentlyRecording(false);
          setIsListening(false);
        }
      };

      audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      analyser = audioContext.createAnalyser();
      mediaStreamSource.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);
      draw();

      recorder.start();
    } catch (err) {
      console.error("Error accessing the microphone:", err);
    }
  };

  const stopMonitoring = () => {
    if (recorder && recorder.state === "recording") {
      recorder.stop();
      setCurrentlyRecording(false);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      if (audioContext) {
        audioContext.close();
      }
    }
  };

  const draw = () => {
    if (!currentlyRecording) return;

    analyser.getByteTimeDomainData(dataArray);

    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const v = dataArray[i] / 128.0;
      sum += Math.abs(v - 1);
    }

    const volume = (sum / dataArray.length) * 100;

    if (volume > VOLUME_THRESHOLD) {
      console.log("Speaking");
      document.body.classList.add("speaking");
      if (recorder.state === "recording") {
        setTimeout(() => {
          if (recorder && recorder.state === "recording") {
            recorder.stop();
            setCurrentlyRecording(false);
            document.body.classList.remove("speaking");
            console.log("Recording stopped");
          }
        }, 3000);
      } else {
        setCurrentlyRecording(true);
        recorder.start();
      }
    }

    animationFrameId = requestAnimationFrame(draw);
  };

  const convertWebmToMp3 = async (webmBlob) => {
    try {
      //check for errors in webmBlob
      if (webmBlob.size == 0) {
        console.log("webmBlob is empty");
        checkSpeechCommands("");
        return;
      }
      const webmFile = new File([webmBlob], "input.webm");
      const file = await fetchFile(webmFile);
      await ffmpeg.writeFile("input.webm", file);
      await ffmpeg.exec(["-i", "input.webm", "output.mp3"]);
      const mp3Data = await ffmpeg.readFile("output.mp3");
      const mp3Blob = new Blob([mp3Data.buffer], { type: "audio/mp3" });
      sendAudioToWit(mp3Blob);
      await ffmpeg.deleteFile("input.webm");
      await ffmpeg.deleteFile("output.mp3");
      //clear memory
      return mp3Blob;
    } catch (err) {
      console.error(err);
      setCurrentlyRecording(false);
      setIsListening(false);
      ffmpeg.terminate();
      ffmpeg = null;
      setFfmpegLoaded(false);
      await loadFFmpeg();
      return new Blob();
    }
  };

  const sendAudioToWit = async (audioBlob) => {
    try {
      const response = await fetch("https://api.wit.ai/dictation?v=20240304", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${witAIToken}`,
          "Content-Type": "audio/mpeg3",
        },
        body: audioBlob,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      const jsonObjects = text.toString().split('"is_final": true,');
      const finalTranscriptionPart =
        jsonObjects[jsonObjects.length - 1].split('"text":')[1];
      const finalTranscription = finalTranscriptionPart.split(",\n")[0];
      console.log(finalTranscription);
      checkSpeechCommands(finalTranscription);
    } catch (error) {
      console.error("Error sending audio to Wit.ai:", error);
    }
  };

  //text checking for commands
  function checkSpeechCommands(finalTranscription) {
    let theMatch = null;
    // Replace "OH" with "O" (case-insensitive)
    finalTranscription = finalTranscription.replace(/\bOH\b/g, "O");
    finalTranscription = finalTranscription.toLowerCase();
    //trim all special characters
    finalTranscription = finalTranscription.replace(/[^a-zA-Z0-9 ]/g, "");
    finalTranscription=finalTranscription.replace(/\binterior\b/g, 'interia');
    // finalTranscription.replace(" ", "")
    //connection commands
    if (!connectStatus) {
      var localSubscribedTopics = JSON.parse(
        localStorage.getItem("subscribeTopics")
      );
      console.log("localSubscribedTopics", localSubscribedTopics.length);

      if (Object.keys(localSubscribedTopics).length == 0) {
        TTS("No mobile devices available to connect");
        return;
      }
      if (
        finalTranscription.includes("yes") &&
        Object.keys(localSubscribedTopics).length > 0
      ) {
        setConnectStatus(true);
      } else if (finalTranscription.includes("no")) {
        //show next user
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }

    //post-connection commands
    // if (
    //   (theMatch =
    //     finalTranscription.match(/\bswitch\s+to\s+(.*)/i) ||
    //     finalTranscription.match(/\bswitched\s+to\s+(.*)/i) ||
    //     finalTranscription.match(/\bswitched\s+(.*)/i))
    // ) {
    //   const result = theMatch[1].trim();
    //   console.log("command matches with switch project" + result);
    //   switchProject(result);
    //   // sendMessage("SimutopiaSwitchProject" + result);
    // } else if (
    //   (theMatch =
    //     finalTranscription.match(/go\s*to\s*(.*)/i) ||
    //     finalTranscription.match(/goto\s*(.*)/i))
    // ) {
    //   const result = theMatch[1].trim();
    //   console.log("command matches with go" + result);
    //   gotoRoom(result);
    //   // sendMessage("SimutopiaGotoRoom" + result);
    // } else
    //else if (
    //   (theMatch =
    //     finalTranscription.match(/select\s+designer\s*(.*)/i) ||
    //     finalTranscription.match(/choose\s+designer\s*(.*)/i) ||
    //     finalTranscription.match(/designer\s*(.*)/i) ||
    //     finalTranscription.match(/design\s*(.*)/i) ||
    //     finalTranscription.match(/selected\s+designer\s*(.*)/i))
    // ) {
    //   const result = theMatch[1].trim();
    //   console.log("command matches with choose designer" + result);
    //   chooseDesigner(result);
    //   // sendMessage("SimutopiaChooseDesigner" + result);
    // }
    console.log("finalTranscription", finalTranscription.length);

    if (finalTranscription.length > 1) {
      if (
        (theMatch =
          finalTranscription.match(/show\s+data\s*(.*)/i) ||
          finalTranscription.match(/show\s+date\s*(.*)/i) ||
          finalTranscription.match(/show\s+metadata\s*(.*)/i) ||
          finalTranscription.match(/show\s+metadate\s*(.*)/i) ||
          finalTranscription.match(/showed\s+metadata\s*(.*)/i) ||
          finalTranscription.match(/showed\s+metadate\s*(.*)/i) ||
          finalTranscription.match(/showed\s+data\s*(.*)/i) ||
          finalTranscription.match(/showed\s+date\s*(.*)/i) ||
          finalTranscription.match(/show\s+information\s*(.*)/i) ||
          finalTranscription.match(/show\s+info\s*(.*)/i) ||
          finalTranscription.match(/show\s+information\s+on\s*(.*)/i) ||
          finalTranscription.match(/show\s+info\s+on\s*(.*)/i) ||
          finalTranscription.match(/show\s+information\s+of\s*(.*)/i) ||
          finalTranscription.match(/show\s+info\s+of\s*(.*)/i) ||
          finalTranscription.match(/showed\s+information\s*(.*)/i) ||
          finalTranscription.match(/showed\s+info\s*(.*)/i) ||
          finalTranscription.match(/showed\s+information\s+on\s*(.*)/i) ||
          finalTranscription.match(/showed\s+info\s+on\s*(.*)/i) ||
          finalTranscription.match(/showed\s+information\s+of\s*(.*)/i) ||
          finalTranscription.match(/showed\s+info\s+of\s*(.*)/i)
        )
    ) {
        const result = theMatch[1].trim();
        console.log("command matches with metadata" + result);
        showMetaData(result);
        // sendMessage("SimutopiaShowMetaData" + result);
    }
     else if (
        (theMatch =
          // finalTranscription.match(/show\s+info\s*(.*)/i) ||
          // finalTranscription.match(/showed\s+info\s*(.*)/i) ||
          finalTranscription.match(/showed\s+menu\s*(.*)/i) ||
          finalTranscription.match(/shown\s+menu\s*(.*)/i) ||
          finalTranscription.match(/shean\s+menu\s*(.*)/i) ||
          finalTranscription.match(/shawn\s+menu\s*(.*)/i) ||
          finalTranscription.match(/show\s+menu\s*(.*)/i) ||
          finalTranscription.match(/show\s+me\s+new\s*(.*)/i) ||
          finalTranscription.match(/show\s+many\s+new\s*(.*)/i) ||
          finalTranscription.match(/show\s+information\s*(.*)/i))
      ) {
        console.log("command matches with show menu");
        TTS("Showing menu");
        showMenuTemporarily();
        // sendMessage("SimutopiaShowMenu");
      } else if (
        (theMatch =
          finalTranscription.match(/go\s+back\s*(.*)/i) ||
          finalTranscription.match(/goes\s+back\s*(.*)/i) ||
          finalTranscription.match(/gone\s+back\s*(.*)/i) ||
          finalTranscription.match(/goon\s+back\s*(.*)/i) ||
          finalTranscription.match(/got\s+back\s*(.*)/i) ||
          finalTranscription.match(/god\s+back\s*(.*)/i) ||
          finalTranscription.match(/back\s*(.*)/i))
      ) {
        console.log("command matches go back");
        goBackOneLevel();
        // sendMessage("SimutopiaGoBack");
      }
      
      else if (
        (theMatch =
          finalTranscription.match(/close\s*data\s*(.*)/i) ||
          finalTranscription.match(/close\s*metadata\s*(.*)/i) ||
          finalTranscription.match(/closes\s*metadata\s*(.*)/i) ||
          finalTranscription.match(/closes\s*data\s*(.*)/i) ||
          finalTranscription.match(/closed\s*data\s*(.*)/i) ||
          finalTranscription.match(/closed\s*metadata\s*(.*)/i) ||
          finalTranscription.match(/close\s*meta\s*data\s*(.*)/i) ||
          finalTranscription.match(/closes\s*meta\s*data\s*(.*)/i) ||
          finalTranscription.match(/hide\s*(.*)/i) ||
          finalTranscription.match(/hidden\s*(.*)/i) ||
          finalTranscription.match(/hide\s*info\s*(.*)/i) ||
          finalTranscription.match(/hid\s*info\s*(.*)/i) ||
          finalTranscription.match(/hidden\s*info\s*(.*)/i) ||
          finalTranscription.match(/hide\s*information\s*(.*)/i) ||
          finalTranscription.match(/hid\s*information\s*(.*)/i) ||
          finalTranscription.match(/hidden\s*information\s*(.*)/i) ||
          finalTranscription.match(/close\s*info\s*(.*)/i) ||
          finalTranscription.match(/closes\s*info\s*(.*)/i) ||
          finalTranscription.match(/closed\s*info\s*(.*)/i) ||
          finalTranscription.match(/close\s*information\s*(.*)/i) ||
          finalTranscription.match(/closes\s*information\s*(.*)/i) ||
          finalTranscription.match(/closed\s*information\s*(.*)/i))
      )
      
       {
        const result = theMatch[1].trim();
        console.log("command matches with close data");
        clearSelectedSphereWithNumber(currentSphereNumber);
        setCurrentSphereNumber(0);
        setMetadata(null);
        TTS("Closing metadata");
      } else if (
        (theMatch =
          finalTranscription.match(/select\s+designer\s*(.*)/i) ||
          finalTranscription.match(/selected\s+designer\s*(.*)/i) ||
          finalTranscription.match(/choose\s+designer\s*(.*)/i) ||
          finalTranscription.match(/designer\s*(.*)/i) ||
          finalTranscription.match(/design\s*(.*)/i) ||
          finalTranscription.match(/selected\s+designer\s*(.*)/i))
      ) {
        const result = theMatch[1].trim();
        console.log("command matches with choose designer" + result);
        chooseDesigner(result);
        // sendMessage("SimutopiaChooseDesigner" + result);
      } else if (
        (theMatch =
          finalTranscription.match(/select\s+project\s*(.*)/i) ||
          finalTranscription.match(/selected\s+project\s*(.*)/i) ||
          finalTranscription.match(/go\s+to\s+project\s*(.*)/i) ||
          finalTranscription.match(/choose\s+project\s*(.*)/i) ||
          finalTranscription.match(/project\s*(.*)/i) ||
          finalTranscription.match(/project\s*(.*)/i) ||
          finalTranscription.match(/selected\s+project\s*(.*)/i))
      ) {
        const result = theMatch[1].trim();
        console.log("command matches with choose project" + result);
        switchProject(result);
        // sendMessage("SimutopiaChooseDesigner" + result);
      } else if (
        (theMatch =
          finalTranscription.match(/select\s+room\s*(.*)/i) ||
          finalTranscription.match(/selected\s+room\s*(.*)/i) ||
          finalTranscription.match(/go\s+to\s+room\s*(.*)/i) ||
          finalTranscription.match(/go\s+two\s+room\s*(.*)/i) ||
          finalTranscription.match(/selected\s+room\s*(.*)/i) ||
          finalTranscription.match(/choose\s+room\s*(.*)/i))
      ) {
        const result = theMatch[1].trim();
        console.log("command matches with choose room" + result);
        gotoRoom(result);
        // sendMessage("SimutopiaChooseDesigner" + result);
      }
      //add matching for show previous menu and other ones that are similiar
      else if (
        (theMatch =
          finalTranscription.match(/show\s+previous\s*(.*)/i) ||
          finalTranscription.match(/show\s+previous\s+menu\s*(.*)/i) ||
          finalTranscription.match(/show\s+previous\s+data\s*(.*)/i) ||
          finalTranscription.match(/showed\s+previous\s+data\s*(.*)/i)) ||
        finalTranscription.match(/showed\s+previous\s+info\s*(.*)/i) ||
        finalTranscription.match(/showe\s+previous\s+info\s*(.*)/i) ||
        finalTranscription.match(/showed\s+previous\s+menu\s*(.*)/i)
      ) {
        handlePrevious();
      } else if (
        (theMatch =
          finalTranscription.match(/show\s+next\s*(.*)/i) ||
          finalTranscription.match(/show\s+next\s+menu\s*(.*)/i) ||
          finalTranscription.match(/show\s+next\s+data\s*(.*)/i) ||
          finalTranscription.match(/showed\s+next\s+data\s*(.*)/i)) ||
        finalTranscription.match(/showed\s+next\s+info\s*(.*)/i) ||
        finalTranscription.match(/showe\s+next\s+info\s*(.*)/i) ||
        finalTranscription.match(/showed\s+next\s+menu\s*(.*)/i)
      ) {
        handleNext();
      } else if (currentSection != null) {
        // finalTranscription = finalTranscription.match(/select\s*(.*)/i)||finalTranscription.match(/choose\s*(.*)/i)||finalTranscription.match(/selected\s*(.*)/i)||finalTranscription.match(/choosed\s*(.*)/i)||finalTranscription.match(/choose\s+to\s*(.*)/i);
        finalTranscription = finalTranscription.replace(/[^a-zA-Z0-9]/g, "");
        console.log("fianltranscription is::" + finalTranscription);
        console.log("CURRENT SECTION  is::" + currentSection);
        if (currentSection === "designer") {
          console.log("inside designer loop of checkSpeechCommands");
          chooseDesigner(finalTranscription);
        } else if (currentSection == "project") {
          switchProject(finalTranscription);
        } else if (currentSection == "room") {
          gotoRoom(finalTranscription);
        }
      } else if (
        !finalTranscription.includes("yes") &&
        !finalTranscription.includes("no")
      ) {
        TTS("I did not understand the command, Please repeat it!");
      }

      // else if (currentSection === "designer") {
      //   console.log("inside designer loop of checkSpeechCommands");
      //   chooseDesigner(lastWord);
      // } else if (currentSection == "project") {
      //   switchProject(lastWord);
      // } else if (currentSection == "room") {
      //   gotoRoom(lastWord);
      // }
    }
  }

  //TTS & STT ends

  useEffect(() => {
    localStorage.setItem("currentSection", currentSection);
    if (!currentSection && currentSection != null && currentSection != "null")
      TTS("Going to " + currentSection + " section");
  }, [currentSection]);

  function goBackOneLevel() {
    Array.prototype.slice
      .call(document.getElementsByTagName("a-sphere"))
      .forEach(function (item) {
        item.remove();
      });
    var localCurrentSection = localStorage.getItem("currentSection");
    var localSharedProjectsCustomer = JSON.parse(
      localStorage.getItem("sharedProjectsCustomer")
    );
    const newSky = document.createElement("a-sky");
    newSky.setAttribute("class", "clickable");
    newSky.setAttribute("src", interia_lobby);
    newSky.setAttribute("rotation", "0 -90 0");
    // Replace the existing <a-sky> element with the new one
    sceneRef.current.replaceChild(newSky, skyRef.current);
    skyRef.current = newSky;
    setData(null);
    localStorage.setItem("data", null);
    setMetadata(null);
    setCurrentSphereNumber(0);
    console.log(localSharedProjectsCustomer);
    if (localCurrentSection === "project") {
      console.log("going back from project");
      TTS("Going back from project");
      setCurrentRoom(null);
      setCurrentProject(null);
      setCurrentDesigner(null);
      setCurrentSection("designer");
      setDisplaySection(Object.keys(localSharedProjectsCustomer));
      setText(
        // `Please say
        // "Interia, select designer ${
        //   Object.keys(localSharedProjectsCustomer)[0]
        // }"
        //  to show that designer's projects`
        "Please press the right trigger and say the Designer name to select the Designer"
      );
    } else if (localCurrentSection === "room") {
      console.log("going back from room");
      TTS("Going back from room");
      setCurrentSection("project");
      setCurrentRoom(null);
      setCurrentProject(null);
      setText(
        // `Please say "Interia, switch to ${
        //   Object.keys(
        //     localSharedProjectsCustomer[
        //       localStorage.getItem("localCurrentDesigner")
        //     ]
        //   )[0]
        // }" to show that project's rooms`
        "Please press the right trigger and say the project name to select the project"
      );
      setDisplaySection(
        Object.keys(
          localSharedProjectsCustomer[
            localStorage.getItem("localCurrentDesigner")
          ]
        )
      );
    } else {
      TTS("Cannot go back from here");
      showMenuTemporarily();
    }
  }
  function chooseDesigner(askedDesigner) {
    console.log("choosing designer", askedDesigner);
    const localSharedProjectsCustomer = JSON.parse(
      localStorage.getItem("sharedProjectsCustomer")
    );
    if (
      askedDesigner &&
      localSharedProjectsCustomer &&
      localStorage.getItem("currentSection") == "designer"
    ) {
      console.log(
        "shared project customer:::::::",
        localSharedProjectsCustomer
      );
      console.log(localSharedProjectsCustomer.hasOwnProperty(askedDesigner));
      console.log(localSharedProjectsCustomer[askedDesigner]);

      if (localSharedProjectsCustomer.hasOwnProperty(askedDesigner)) {
        setCurrentDesigner(askedDesigner);
        TTS("Selected designer " + askedDesigner);
      } else {
        if (askedDesigner == null) TTS("No such designer exists");
        else
          TTS(
            askedDesigner +
              " does not exist in the list of designers. Please select a designer from the list."
          );
        showMenuTemporarily();
      }
    } else {
      showMenuTemporarily();
    }
  }

  function switchProject(askedProject) {
    console.log("switching project", askedProject);
    const localSharedProjectsCustomer = JSON.parse(
      localStorage.getItem("sharedProjectsCustomer")
    );
    const localCurrentDesigner = localStorage.getItem("localCurrentDesigner");
    if (
      askedProject &&
      localCurrentDesigner &&
      localSharedProjectsCustomer &&
      localStorage.getItem("currentSection") == "project"
    ) {
      console.log(
        "shared proejcts customer project",
        localSharedProjectsCustomer[localCurrentDesigner]
      );
      if (
        localSharedProjectsCustomer[localCurrentDesigner].hasOwnProperty(
          askedProject
        )
      ) {
        setCurrentProject(askedProject);
        TTS("Selected project " + askedProject);
      } else {
        if (askedProject == null) TTS("No such project exists");
        else
          TTS(
            askedProject +
              " does not exist in the list of projects. Please select a project from the list."
          );
        showMenuTemporarily();
      }
    } else {
      showMenuTemporarily();
    }
  }

  function gotoRoom(askedRoom) {
    const localSharedProjectsCustomer = JSON.parse(
      localStorage.getItem("sharedProjectsCustomer")
    );
    const localCurrentDesigner = localStorage.getItem("localCurrentDesigner");
    const localCurrentProject = localStorage.getItem("localCurrentProject");
    console.log("going to room", askedRoom);
    if (
      askedRoom &&
      localCurrentDesigner &&
      localCurrentProject &&
      localSharedProjectsCustomer &&
      localStorage.getItem("currentSection") == "room"
    ) {
      if (
        localSharedProjectsCustomer[localCurrentDesigner][
          localCurrentProject
        ].hasOwnProperty(askedRoom)
      ) {
        setCurrentRoom(askedRoom);
        TTS("Selected room " + askedRoom);
      } else {
        if (askedRoom == null) TTS("No such room exists");
        else
          TTS(
            askedRoom +
              " does not exist in the list of rooms. Please select a room from the list."
          );
        showMenuTemporarily();
      }
    } else {
      showMenuTemporarily();
    }
  }

  function textToNumber(text) {
    const units = {
      zero: 0,
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5,
      six: 6,
      seven: 7,
      eight: 8,
      nine: 9,
    };

    const teens = {
      ten: 10,
      eleven: 11,
      twelve: 12,
      thirteen: 13,
      fourteen: 14,
      fifteen: 15,
      sixteen: 16,
      seventeen: 17,
      eighteen: 18,
      nineteen: 19,
    };

    const tens = {
      twenty: 20,
      thirty: 30,
      forty: 40,
      fifty: 50,
      sixty: 60,
      seventy: 70,
      eighty: 80,
      ninety: 90,
    };

    const scales = {
      hundred: 100,
      thousand: 1000,
      million: 1000000,
    };

    function parseNumber(words) {
      let result = 0;
      let current = 0;

      for (let word of words) {
        if (units[word] !== undefined) {
          current += units[word];
        } else if (teens[word] !== undefined) {
          current += teens[word];
        } else if (tens[word] !== undefined) {
          current += tens[word];
        } else if (scales[word] !== undefined) {
          current *= scales[word];
          result += current;
          current = 0;
        }
      }

      return result + current;
    }

    const words = text.toLowerCase().split(" ");
    let numberArray = [];
    let buffer = [];

    for (let word of words) {
      if (
        units[word] !== undefined ||
        teens[word] !== undefined ||
        tens[word] !== undefined ||
        scales[word] !== undefined
      ) {
        buffer.push(word);
      } else {
        if (buffer.length > 0) {
          numberArray.push(parseNumber(buffer));
          buffer = [];
        }
        numberArray.push(word);
      }
    }

    if (buffer.length > 0) {
      numberArray.push(parseNumber(buffer));
    }

    return numberArray;
  }

  function showMetaData(askedMetaData) {
    console.log("showing metadata", askedMetaData);
    //get only number from askedMetaData
    var number = askedMetaData;
    number = textToNumber(number)[1];
    console.log("number", number);
    const localData = JSON.parse(localStorage.getItem("data"));
    if (localData && localData[number]) {
      setCurrentSphereNumber(number);
      TTS("Showing information on Number " + number);

      // if (askedMetaData.includes("one")) {
      //   setCurrentSphereNumber(1);
      //   TTS("Showing metadata of sphere one");
      // }
    } else {
      console.log("No such metadata Number exists");
      TTS("No such metadata Number exists");
      showMenuTemporarily();
    }
  }

  const columnWidth = 4; // Width of each column
  const columnGap = 1; // Gap between columns
  const maxItemsPerColumn = 3; // Maximum number of items per column
  const maxItemsPerPage = 5; // Maximum number of items to display per page
  const totalItems = displaySection ? displaySection.length : 0; // Total number of items
  const totalPages = Math.ceil(totalItems / maxItemsPerPage); // Total number of pages

  const menuWidth =
    Math.ceil(maxItemsPerPage / maxItemsPerColumn) * (columnWidth + columnGap) -
    columnGap; // Calculate the total menu width
  const centerOffset = menuWidth / 2; // Calculate the center offset
  const itemHeight = 1; // Height of each item
  const itemGap = 0.5; // Gap between items

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
    showMenuTemporarily();
  };

  const handleNext = () => {
    const displaySection = JSON.parse(
      localStorage.getItem("localDisplaySection")
    );
    const totalPages = Math.ceil(displaySection.length / maxItemsPerPage);
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
    showMenuTemporarily();
  };

  const Menu = () => {
    // Calculate the items to display for the current page
    const startIndex = currentPage * maxItemsPerPage;
    const endIndex = Math.min(startIndex + maxItemsPerPage, totalItems);
    const currentItems = displaySection
      ? displaySection.slice(startIndex, endIndex)
      : [];
  
    // Determine the title text and color based on the current section
    let titleText = '';
    let titleColor = 'white';
    
    if (currentSection === 'designer') {
      titleText = currentDesigner || 'Designers';
      titleColor = 'green';
    } else if (currentSection === 'project') {
      titleText = currentProject || 'Projects';
      titleColor = 'blue';
    } else if (currentSection === 'room') {
      titleText = currentRoom || 'Rooms';
      titleColor = 'red';
    }
  
    return (
      <a-entity>
        {displaySection&&currentSection!=null && (
          <>
            {/* Title Tile */}
            <a-plane
              position="0 -2 -12"
              width="6"
              height="1"
              color={titleColor}
              class="title-tile"
              opacity="1"
            >
              <a-text
                value={titleText}
                align="center"
                color="white"
                width="6"
                position="0 0 0.1"
                backgroundColor={titleColor}
                opacity="1"
                font="kelsonsans" // You can adjust or use a different font if needed
                letterSpacing="0.1"
              />
            </a-plane>
  
            {/* Menu Items */}
            {currentItems &&
              currentItems.map((section, index) => {
                const columnIndex = Math.floor(index / maxItemsPerColumn); // Calculate which column this item belongs to
                const rowIndex = index % maxItemsPerColumn; // Calculate the row index within the column
                const xPos = columnIndex * (columnWidth + columnGap) - centerOffset; // Calculate the x position based on the column index with added gap and center offset
                const yPos = -5 - rowIndex * (itemHeight + itemGap); // Calculate the y position based on the row index with item height and gap
                return (
                  <a-plane
                    key={section}
                    class="menu"
                    color={
                      (section === currentDesigner &&
                        currentSection === "designer") ||
                      (section === currentProject &&
                        currentSection === "project") ||
                      (section === currentRoom && currentSection === "room")
                        ? "blue"
                        : "black"
                    }
                    opacity="1"
                    width="4"
                    height="1"
                    position={`${xPos} ${yPos} -12`}
                    animation={`property: position; to: ${xPos} ${yPos} -12; delay: ${
                      index * 20
                    }; dur: 1500; easing: easeInOutExpo; loop: 0`}
                  >
                    <a-text
                      value={section}
                      align="center"
                      color="white"
                      width="8"
                      position="0 0 0.2"
                      backgroundColor="black"
                      opacity="1"
                    />
                  </a-plane>
                );
              })}
  
            {/* Navigation Buttons */}
            {currentPage > 0 && (
              <a-plane
                position={`-${centerOffset + 2} -10 -12`}
                width="2"
                height="1"
                color="gray"
                class="navigate"
              >
                <a-text value="Previous" align="center" color="white" />
              </a-plane>
            )}
  
            {currentPage < totalPages - 1 && (
              <a-plane
                position={`${centerOffset + 2} -10 -12`}
                width="2"
                height="1"
                color="gray"
                class="navigate"
              >
                <a-text value="Next" align="center" color="white" />
              </a-plane>
            )}
          </>
        )}
      </a-entity>
    );
  };

  useEffect(() => {
    skyRef.current.setAttribute("src", interia_lobby);
  }, []);

  // Defining the a-text sections of the menu ends

  async function DS(localSharedProject) {
    var requiredDS = {};
    console.log("localSharedProject", localSharedProject);
    for (const mail in localSharedProject) {
      var trimmedmail = "";
      if (mail.indexOf(".") < mail.indexOf("@")) {
        trimmedmail = mail.split(".")[0];
      } else {
        trimmedmail = mail.split("@")[0];
      }
      if (trimmedmail == "shoukath") trimmedmail = "showcase";
      for (const project of localSharedProject[mail]) {
        if (!requiredDS[trimmedmail]) {
          requiredDS[trimmedmail] = {};
        }
        if (!requiredDS[trimmedmail][project]) {
          requiredDS[trimmedmail][project] = {};
        }
        var currentRef = baseURL + "/" + mail + "/" + project;
        const roomList = await getRooms(currentRef);
        roomList.forEach((room) => {
          requiredDS[trimmedmail][project][room] =
            currentRef + "/" + room + "/" + room + ".png";
        });
      }
    }
    requiredDS = sanitizeKeys(requiredDS);
    setSharedProjectsCustomer(requiredDS);
    return requiredDS;
  }

  async function getRooms(selectedRef) {
    var roomPath = selectedRef;
    var roomList = [];
    const listRef = ref(storage, roomPath);
    const listResult = await listAll(listRef);
    listResult.prefixes.forEach((prefixRef) => {
      roomList.push(prefixRef.name);
    });
    return roomList;
  }

  function trimMail(mail) {
    var trimmedmail = "";
    if (mail.indexOf(".") < mail.indexOf("@")) {
      trimmedmail = mail.split(".")[0];
    } else {
      trimmedmail = mail.split("@")[0];
    }
    return trimmedmail;
  }

  const lineHeight = 0.5; // Height per line of text
  const baseHeight = 1; // Base height for the heading
  const metadataLines = metadata ? metadata.split("\n").length : 0;
  // Split metadata by new lines
  const planeHeight = baseHeight + metadataLines * lineHeight;

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/*to remove starts */}
      <button
        onMouseDown={startMonitoring}
        onMouseUp={stopMonitoring}
        style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}
      >
        Press and Hold to Record
      </button>

      {/* to remove ends */}
      <a-scene
        id="scene"
        scene
        ref={sceneRef}
        link-controls
        vr-mode-ui
        style={{ width: "100%", height: "100%" }}
      >
        <a-camera
          id="camera"
          ref={cameraRef}
          rotation-reader
          camera
          wasd-controls="acceleration:0"
        >
          {/* Menu starts */}
          {showMenu && (
            <a-entity id="menu" position="2 5 2">
              {connectStatus && (
                <a-text
                  color="black"
                  width="8"
                  id="code"
                  position="0 -10 -12"
                  align="center"
                  value={text}
                />
              )}
              <Menu />
            </a-entity>
          )}
          {/* Menu ends */}
          <a-entity
            cursor="rayOrigin: mouse"
            raycaster_cursor
            raycaster="objects: .clickable; far: 1000;"
          ></a-entity>
          {!connectStatus && (
            <a-text
              color="black"
              width="8"
              id="code"
              position="0 -5 -12"
              align="center"
              value={text}
            />
          )}
          {metadata && (
            <a-plane
              color="black"
              opacity="0.5"
              width="8"
              height={planeHeight}
              position="0 -5 -12"
            >
              <a-text
                color="white"
                width="8"
                id="metadata-title"
                opacity="1"
                position="-3.5 0.5 0.2" // Position heading at the top left
                align="left"
                value={`Information on number ${currentSphereNumber}`}
              ></a-text>
              <a-text
                color="white"
                width="8"
                id="metadata-content"
                opacity="1"
                position="-3.5 -0.5 0.2" // Position metadata below the heading
                align="left"
                value={metadata}
              />
            </a-plane>
          )}
          {/* Image to rotate */}
          {skyLoading && (
            <a-image
              src={interia_logo_no_BG}
              position="0 0 -1"
              scale="0.25 0.2 0.25"
              rotation="0 0 0"
              animation__rotate="property: rotation; to: 0 0 360; loop: true; dur: 2000; easing: easeInOutQuad"
            ></a-image>
          )}
          {!connectStatus &&
            Array.from(subscribedTopics).map(
              (topic, index) =>
                index === currentIndex && (
                  <a-text
                    position="-2.75 0 -12"
                    color="black"
                    width="8"
                    value={
                      "Click the right controller trigger and say YES to connect with '" +
                      trimMail(topic.username) +
                      "' or NO to see other devices."
                    }
                  ></a-text>
                )
            )}
          {isListening && (
            <a-image src={recording} position="-3 2 -8"></a-image>
          )}
          <a-text
            color="black"
            width="8"
            value={consoleText}
            position="2 -2 -10"
          ></a-text>
        </a-camera>
        <a-sky ref={skyRef} rotation="0 -90 0"></a-sky>
        <a-entity
            right-controllor
            oculus-touch-controls="hand: right"
            id="right-controller"
            raycaster="objects: .menu, .metadata, .navigate; far: 10000;showLine: true;direction: -0.5 -1 -2;"
          ></a-entity>
        <style>
          {`
  .a-enter-vr-button {
    height: 100px !important;
    width: 100px !important;
  }
`}
        </style>
      </a-scene>
    </div>
  );
}

export default QuestPage;

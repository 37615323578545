export const userStorage = {
  users: "multivrseInteriaUsers",
  sharedProjectsCustomer: "sharedProjectsCustomer",
  sharedProjectsDesigner: "sharedProjectsDesigner",
};
export const URL = {
  authServer: "https://firebase-admin-sdk-node-server.vercel.app",
  baseURL:"gs://interia-d5652.appspot.com",
  MQTTServer:"wss://3c9e8ab2c2cf4d7f8711183b2b91a0c4.s1.eu.hivemq.cloud:8884/mqtt"
};

export const MQTTOptions = {
  protocol: 'wss',
  username: 'shoukath', // If authentication is required
  password: 'Simutopia1!@#100'  // If authentication is required
};

export const witAIToken="A3FPPHVJIDBEJ47AW7MYJNJL5EKR2GPU";

export const defaults = {
  user: {
    firstname: "X",
    lastname: "YZ",
  },
};

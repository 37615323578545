import React, { useEffect, useState, useContext } from "react";
import ReactDom from "react-dom";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import { Typography, List, ListItem, ClickAwayListener } from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ListItemButton from "@mui/material/ListItemButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FilterHdrTwoToneIcon from '@mui/icons-material/FilterHdrTwoTone';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import AddIcon from "@mui/icons-material/Add";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import ListItemIcon from "@mui/material/ListItemIcon";
import Popper from "@mui/material/Popper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from "@mui/material/Button";
import FolderIcon from "@mui/icons-material/Folder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { AppContext } from "../../AppContext.js";
import EditableTextField from "./EditableTextField.js";
import {
  ref,
  listAll,
  copy,
  uploadBytes,
  uploadString,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import { auth, store } from "../authentication/Firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { userStorage } from "../constants";
import { storage } from "../authentication/Firebase.js";
import { lightBlue } from "@mui/material/colors";

const imageExtensionList = [
  "mp4",
  "mov",
  "mkv",
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nef",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
  "heic",
  "heif",
];

const drawerWidth = 400;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ProjectBrowser() {
  const {
    inHome,
    setInHome,
    selectedRef,
    setSelectedRef,
    open,
    setOpen,
    anchorE1,
    setAnchorE1,
    snackOpen,
    handleSnackClick,
    handleSnackClose,
    action,
    message,
    setMessage,
    setfs,
    fs,
    storageRef,
    setStorageRef,
    setConfirmDialogText,
    useConfirmDialog,
  } = useContext(AppContext);
  const theme = useTheme();
  const [selectedProject, setSelectedProject] = useState(null);
  const [sharedProjects, setSharedProjects] = useState([]);
  const [menuopen, setmenuOpen] = useState(false);
  const [addRoomOpen, setAddRoomOpen] = useState(false);
  const [addRoomAnchorEl, setAddRoomAnchorEl] = useState(null);
  const [inputFields, setInputFields] = useState([]);
  const [roomCount, setroomCount] = useState(1);
  const [anchorE2, setAnchorE2] = useState(null);
  const [placement, setPlacement] = useState();
  const [desAnchorEl, setDesAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [desOpen, setDesOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const openPopper = Boolean(anchorE1);
  const popperId = open ? "simple-popper" : undefined;
  const openMenu = Boolean(anchorE2);
  const MenuId = menuopen ? "menu-popper" : undefined;
  const [checked, setChecked] = React.useState([-1]);
  const [projectsLoading, setProjectsLoading] = useState(false);

  const handlePopperClick = (e) => {
    setAnchorE1(
      anchorE1 ? null : document.getElementsByClassName("manager")[0]
    );
    handleDrawerClose();
  };

  const handleAddProject = (event) => {
    event.stopPropagation()
    storageRef.isBeingModified = true;
    setStorageRef(storageRef);
    createNewProject();
  };

  const handleAddRoom = (reference) => {
    createNewRoom(reference);
  };

  const handleAddImage = (e) => {
    
  };

  const handleDeleteProject = (reference) => {
    deleteFolder(reference);
  };

  const handleDeleteRoom = (reference) => {
    deleteFolder(reference);
  };

  const handleDeleteImage = (e) => {
    
  };

  const handleShareProject = (e) => {
    
  };

  const handleNameChange = async (reference, newName, rowType) => {

    const showMessage = (msg) => {
      setMessage(msg);
      handleSnackClick();
    };

    try {
      var jsonFileNAme = rowType + '-metadata.json';
      // Create a reference to the JSON file
      const fileRef = ref(reference, jsonFileNAme);
  
      // Get the download URL for the JSON file
      const url = await getDownloadURL(fileRef);
  
      // Fetch the JSON content from the URL
      const response = await fetch(url);
      if (!response.ok) {
       showMessage("Failed to fetch the file");
      }
      const jsonData = await response.json();
  
      // Update the name field with the new name
      jsonData.name = newName;
  
      // Convert the updated JSON data to a string
      const updatedJsonString = JSON.stringify(jsonData);
  
      // Upload the updated JSON file back to Firebase Storage
      await uploadString(fileRef, updatedJsonString, 'raw', {
        contentType: 'application/json'
      });
       
    } catch (error) {
      // Handle any errors that occurred 
      console.error("Error:", error.message);
      showMessage("An error occurred while updating the name. Please try again.");
    }  
  };

  const handleNoOp = (e) => {
    
  };

  useEffect(() => {
    //setting storage reference to current user's email
    var tempRef = ref(storage, "/" + auth.currentUser.email);
    tempRef.isBeingModified = false;
    setStorageRef(tempRef);
    load();
  }, [open, snackOpen]);

  useEffect(() => {
  }, [selectedRef]);

  useEffect(() => {
    if (inHome === true) {
      handleDesClose();
      //clear selected project,input fields,anchors,emaillist and room count
      setSelectedProject(null);
      setInputFields([]);
      setAnchorE1(null);
      setEmailList([]);
      setEmail("");
      setroomCount(1);
      setAnchorE2(null);
      setmenuOpen(false);
      setDesAnchorEl(null);
      setSelectedMail(null);
    }
  }, [inHome]);

  //checkbox toggling function for multi-select in editing projects for customers
  const handleToggleCheckBox = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  //function to handle share project button click
  const handleDesClick = (event) => {
    console.log("event in des click", event);
    //place theanchor where the selected project is
    var element = document.querySelector(`[data-reference="${selectedRef}"]`);
    setDesAnchorEl(element);
    setDesOpen((prev) => !prev);
  };
  //function to handle share project button close
  const handleDesClose = () => {
    setSelectedProject(null);
    setEmailList([]);
    setEmail("");
    setDesOpen(false);
    setDesAnchorEl(null);
  };
  //function to handle add email button click
  const handleAddEmail = () => {
    if (email && !emailList.includes(email)) {
      setEmailList([...emailList, email]);
      setEmail("");
    }
  };
  //function to handle share project to add mails in DB
  const handleDesSubmit = async () => {
    try {
      for (const email of emailList) {
        setMessage("Sharing Project with " + email);
        handleSnackClick();
        const designerDb = doc(
          store,
          userStorage.sharedProjectsDesigner,
          auth.currentUser.email
        );
        const customerDb = doc(
          store,
          userStorage.sharedProjectsCustomer,
          email
        );
        var userExists = false;
        if ((await getDoc(customerDb)).exists()) {
          userExists = true;
        }
        if (userExists) {
          var customerData = (await getDoc(customerDb)).data()[
            auth.currentUser.email
          ];
          var designerData = (await getDoc(designerDb)).data()[email];
          if (!designerData) {
            let selectedProjectArray = [];
            selectedProjectArray.push(selectedProject);
            designerData = selectedProjectArray;
          } else if (!designerData.includes(selectedProject)) {
            designerData.push(selectedProject);
          }
          await setDoc(designerDb, { [email]: designerData }, { merge: true });
          var customerData = (await getDoc(customerDb)).data()[
            auth.currentUser.email
          ];
          if (!customerData) {
            let selectedProjectArray = [];
            selectedProjectArray.push(selectedProject);
            customerData = selectedProjectArray;
          } else if (!customerData.includes(selectedProject)) {
            customerData.push(selectedProject);
          }
          await setDoc(
            customerDb,
            {
              [auth.currentUser.email]: customerData,
            },
            { merge: true }
          );
        } else {
          setMessage(email + " in the list does not exist!");
          handleSnackClick();
          return;
        }
      }
      await getSharedProjects();
      setMessage("Project Shared!");
      handleSnackClick();
      handleDesClose();
    } catch (error) {
      //show error
      setMessage("User in the list does not exist!");
      handleSnackClick();
    }
  };
  //function to handle delete email button click
  const handleDeleteEmail = (emailToDelete) => {
    setEmailList(emailList.filter((email) => email !== emailToDelete));
  };
  //function to handle edit project to edit shared mails in DB
  const handleCusSubmit = async () => {
    try {
      const customerDb = doc(
        store,
        userStorage.sharedProjectsCustomer,
        selectedMail
      );
      var customerData = (await getDoc(customerDb)).data();
      var selectedProjects = [];
      for (const index of checked) {
        selectedProjects.push(projectList[index]);
      }
      customerData[auth.currentUser.email] = selectedProjects;
      const designerDb = doc(
        store,
        userStorage.sharedProjectsDesigner,
        auth.currentUser.email
      );
      var designerData = (await getDoc(designerDb)).data();
      designerData[selectedMail] = selectedProjects;
      await setDoc(designerDb, designerData, { merge: true });
      await setDoc(customerDb, customerData, { merge: true });
      await getSharedProjects();
      setCustomerAnchorEl(null);
      setCustomerOpen(false);
      setSelectedMail("");
      setMessage("Share access updated!");
      handleSnackClick();
    } catch (error) {
      setMessage("this user does not exist!");
      handleSnackClick();
    }
  };
  //function to handle edit project button open
  const handleCusClick = (event, email) => {
    event.preventDefault();
    setSelectedMail(email);
    setChecked([]);
    sharedProjects[email].forEach((project) => {
      setChecked((prev) => [...prev, projectList.indexOf(project)]);
    });
    console.log(event);

    setCustomerAnchorEl(event.target);
    setCustomerOpen(open);
  };
  //function to upload file
  async function uploadFile(storage, reference, file) {
    setIsFileUploading(true);
    var ele = document.getElementById("circularprogress");
    const node = (
      <Stack
        id="circular"
        direction="row"
        alignItems="center"
        alignContent="left"
        spacing={2}
      >
        <CircularProgress size="15px" />
        <p>Uploading {file.name}</p>
      </Stack>
    );
    ReactDom.render(node, ele);
    const storageRef = ref(storage, reference);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        ReactDom.unmountComponentAtNode(ele);
        document.getElementById("circularprogress").remove();
        setIsFileUploading(false);
        setMessage("File Uploaded!");
        handleSnackClick();
      })
      .catch((error) => {
        ReactDom.unmountComponentAtNode(ele);
        document.getElementById("circularprogress").remove();
        setIsFileUploading(false);
        setMessage("Error uploading file!");
        handleSnackClick();
      });
  }
  //function to upload folder by creating empty json files
  async function uploadFolder(reference, folderId, foldername, metaDataPrefix) {
    const newDir = ref(reference, folderId);

    if(metaDataPrefix){
      const ghostFile = ref(newDir, metaDataPrefix + "-metadata.json");
      var jsonData = { name: foldername };

      uploadString(ghostFile, JSON.stringify(jsonData), "raw", {
        contentType: "application/json",
      })
        .then(() => {
          // load();
          // setMessage("Project Created!");
          // handleSnackClick();
        })
        .catch((error) => {
          setMessage("Error creating folder!");
          handleSnackClick();
        });
    }
  }

  async function moveFolder(reference, currentName, newName){
    const currentDir = ref(reference, currentName);
    const newDir = ref(reference, newName);

    listAll(currentDir).then((res) => {
      res.prefixes.forEach((prefix) => {
        // copy(prefix, newDir.child(prefix.name));
      });
    }).catch((error) => {
      console.error("Error copying folder:", error);
    });
  }

  //function to get files from firebase storage
  async function getFiles(storageRef) {
    if (storageRef == null) return null;
    try {
      const listResult = await listAll(storageRef);
      const result = {};

      // Process prefixes (subdirectories)
      for (const prefix of listResult.prefixes) {
        if (prefix != null) {
          const subResult = await getFiles(prefix);
          result[prefix.name] = {
            reference: prefix, // Include Firebase reference object
          };
          Object.assign(result[prefix.name], subResult);
        }
      }

      // Process items (files)
      for (const item of listResult.items) {
        if (item != null) {
          result[item.name] = {
            reference: item, // Include Firebase reference object
            type: "file",
          };
        }
      }
      return result;
    } catch (error) {
      console.error("Error fetching files:", error);
      return null;
    }
  }
  //function to delete file from firebase storage
  async function deleteRef(reference) {
    try {
      // Attempt to delete the hidden file after successful upload
      var element = document.querySelector(`[data-reference="${reference}"]`);
      const type = element.getAttribute("data-type");
      if (type === "file") {
        var ele = document.createElement("div");
        element.appendChild(ele);
        ele.id = "circularprogress";
        const node = (
          <Stack
            id="circular"
            direction="row"
            alignItems="center"
            alignContent="left"
            spacing={2}
          >
            <CircularProgress size="15px" />
            <p>Deleting {element.innerText}</p>
          </Stack>
        );
        ReactDom.render(node, ele);
        reference = ref(storage, reference);
        await deleteObject(reference);
        //get metadata reference
        var metadataRef = reference
          .toString()
          .replace(".png", "-metadata.json");
        console.log(metadataRef);
        updateMetadata(storage, metadataRef, {});
        if (ele) ReactDom.unmountComponentAtNode(ele);
        setMessage("File Deleted!");
        handleSnackClick();
      } else {
        const folderRef = ref(storage, reference);
        // setConfirmDialogText("Are you sure you want to delete this folder?");
        // const result = await confirm();
        // if (!result) {
        //   return;
        // }
        deleteFolder(folderRef);
      }
    } catch (error) {
      if (ele) ReactDom.unmountComponentAtNode(ele);
      setMessage("Error deleting file!");
      handleSnackClick();
    }
  }
  //function to delete folder from firebase storage
  async function deleteFolder(ref) {
    try {
      listAll(ref).then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef);
        });
        res.prefixes.forEach((folderRef) => {
          deleteFolder(folderRef);
        });
      });
      if (selectedRef && selectedRef.split("/").length === 5) {
        //delete this project from shared projects
        const designerDb = doc(
          store,
          userStorage.sharedProjectsDesigner,
          auth.currentUser.email
        );
        const designerData = (await getDoc(designerDb)).data();
        var selectedProject = selectedRef.split("/").slice(-1)[0];
        //find selected project in shared projects
        Object.keys(designerData).forEach((email) => {
          if (designerData[email].includes(selectedProject)) {
            //remove selected project from shared projects
            designerData[email] = designerData[email].filter(
              (project) => project !== selectedProject
            );
          }
        });
        console.log(designerData);
        await setDoc(designerDb, designerData);
        //check if selected project is in shared projects of any customer
        Object.keys(sharedProjects).forEach(async (email) => {
          if (sharedProjects[email].includes(selectedProject)) {
            const customerDb = doc(
              store,
              userStorage.sharedProjectsCustomer,
              email
            );
            const customerData = (await getDoc(customerDb)).data();
            //remove selected project from shared projects
            customerData[auth.currentUser.email] = customerData[
              auth.currentUser.email
            ].filter((project) => project !== selectedProject);
            await setDoc(customerDb, customerData);
          }
        });
        //update shared projects in DB
      }
      ref.isBeingModified = false;
      setInHome(true);
      load();
    } catch (error) {
      console.log(error);
      setMessage("Error deleting folder!");
      handleSnackClick();
    }
  }
  //function to handle menu click for project options
  const handleMenuClick = (event, newPlacement) => {
    event.preventDefault();
    var reference =
      event.target.parentElement.parentElement.getAttribute("data-reference");
    //display only if clicked node is project
    if (reference == null) {
      return;
    }
    if (
      reference.split("/").length > 4
      // reference.endsWith(".png")
    ) {
      setSelectedRef(reference);
      console.log("event in menu click", event);

      setAnchorE2(event.target);
      setmenuOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    }
  };
  //function to handle drawer open
  const handleDrawerOpen = () => {
    setOpen(true);
    setAnchorE1(null);
    load();
  };
  //function to handle drawer close
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //function to handle drag over project browser
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.style.backgroundColor = "rgba(0, 0, 0, 0.2)";
    e.target.addEventListener("dragleave", () => {
      e.target.style.backgroundColor = "transparent";
    });
  };
  //function to handle drop in project browser
  const handleDrop = async (e) => {
    e.preventDefault();
    if (isFileUploading) {
      setMessage("Please wait for the current upload to complete!");
      handleSnackClick();
      return;
    }
    // Find the nearest element with data-type attribute
    let element = e.target;
    var reference =
      element.parentElement.parentElement.getAttribute("data-reference");
    reference = reference.split("/").slice(0, 6).join("/");
    if (reference.split("/").length !== 6) return;
    var parentFolderElement = document.querySelector(
      `[data-reference="${reference}"]`
    );
    var ele = document.createElement("div");
    parentFolderElement.appendChild(ele);
    ele.id = "circularprogress";
    const parentFolderName = reference.split("/").slice(-1)[0];
    //CHECK NUMBER OF FILES BEING DRAGGED
    var length = e.dataTransfer.files.length;
    if (length > 1) {
      setMessage("Please drag only one file at a time!");
      handleSnackClick();
      return;
    }
    const file = e.dataTransfer.files[0];
    //check file extesntion
    var extension = file.name.split(".").slice(-1)[0];
    if (!imageExtensionList.includes(extension)) {
      setMessage("Invalid file type!");
      handleSnackClick();
      return;
    }
    // check if there exists a image already in parent folder
    var imageElement = document.querySelector(
      `[data-reference="${reference}/${parentFolderName}.png"]`
    );
    if (imageElement) {
      setConfirmDialogText(
        "An image already exists in this folder. Do you want to replace it?"
      );
      const result = await confirm();
      if (!result) {
        return;
      }
    }
    if (file) {
      var filename = reference + "/" + parentFolderName + ".png";
      uploadFile(storage, filename, file);
    }
    load();
  };
  var i = 0;
  //function to render tree structure of project browser
  const renderTree = (nodes, rowType) => {

    if (nodes)
      return Object.entries(nodes).map(([nodeName, nodeData]) => {
        if (!nodeData.reference.toString().endsWith(".json")) {
          if (nodeData.type === "file") {
            i = i + 1;
            
            const handleNameChangeSave = (newName) => {
              handleNameChange(nodeData.reference, newName);
            };

            return (
              <>
                {i === 1 && <Divider />}
                <TreeItem
                  icon={<InsertDriveFileIcon />}
                  key={nodeName + i}
                  nodeId={nodeName + i}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {/* Column 1: Custom icon */}
                      <div style={{ flex: 'none', marginRight: '12px' }}>
                        <FilterHdrTwoToneIcon fontSize="small" />
                      </div>

                      {/* Column 2: Node text */}
                      <div style={{ flex: '1', marginRight: '12px' }}>
                        <EditableTextField initialText={nodeName} onSave={handleNameChangeSave} />
                      </div>

                      {/* Column 3: Action icons aligned to the right */}
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        <IconButton size="small">
                          <AddCircleOutlineIcon onClick={handleNoOp} fontSize="small" style={{visibility: 'hidden'}}/>
                        </IconButton>
                        <IconButton size="small">
                          <DeleteOutlineIcon onClick={handleDeleteImage} fontSize="small"/>
                        </IconButton>
                        <IconButton size="small">
                          <ReplyOutlinedIcon onClick={handleNoOp} fontSize="small" style={{visibility: 'hidden'}}/>                          
                        </IconButton>
                      </div>
                    </div>
                  }
                  draggable="true"
                  onDragStart={(event) => {
                    event.dataTransfer.setData("text/plain", nodeName);
                    setSelectedRef(event.target.getAttribute("data-reference"));
                  }}
                  data-reference={nodeData.reference}
                  data-type="file" // Add data-type attribute for file nodes
                />
                <Divider />
              </>
            );
          } else {
            const { reference, ...displayData } = nodeData;
            i = i + 1;

            reference.isBeingModified = false;

            const handleDeleteClick = (event) => {
              event.stopPropagation();
              reference.isBeingModified = true;
              if (rowType === 'projects') {
                handleDeleteProject(reference);
              } else {
                handleDeleteRoom(reference);
              }
            };

            const handleAddClick = (event) => {
              event.stopPropagation()
              reference.isBeingModified = true;
              if (rowType === 'projects') {
                handleAddRoom(reference);
              } else {
                handleAddImage(reference);
              }
            };

            const handleNameChangeSave = (newName) => {
              reference.isBeingModified = true;
              if (rowType === 'projects') {
                handleNameChange(nodeData.reference, newName, 'project');
              } else {
                handleNameChange(nodeData.reference, newName, 'room');
              }              
            };

            return (
              <>
                <TreeItem
                  expandIcon={<ExpandMoreTwoToneIcon />}
                  collapseIcon={<ExpandLessTwoToneIcon />}
                  key={nodeName + i}
                  nodeId={nodeName + i}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {/* Column 1: Custom icon */}
                      <div style={{ flex: 'none', marginRight: '12px' }}>
                        {rowType === 'projects' ? (
                          <HomeTwoToneIcon fontSize="small" />
                        ) : (
                          <MeetingRoomTwoToneIcon fontSize="small" />
                        )}
                      </div>

                      {/* Column 2: Node text */}
                      <div style={{ flex: '1', marginRight: '12px' }}>
                        <EditableTextField initialText={nodeName} onSave={handleNameChangeSave} />
                      </div>

                      {/* Column 3: Action icons aligned to the right */}
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        <CircularProgress size="15px" fontSize="small"
                          style={{
                            visibility: (!projectsLoading && reference.isBeingModified) ? 'visible' : 'hidden'
                          }}
                        />
                        <IconButton size="small">
                          <AddCircleOutlineIcon onClick={handleAddClick} fontSize="small"/>
                        </IconButton>
                        <IconButton size="small">
                          <DeleteOutlineIcon onClick={handleDeleteClick} fontSize="small"/>
                        </IconButton>
                        <IconButton size="small">
                          <ReplyOutlinedIcon
                            fontSize="small"
                            onClick={rowType === 'projects' ? handleShareProject : handleNoOp}
                            style={{
                              transform: 'scaleX(-1)', // Flip icon horizontally
                              display: 'block',
                              visibility: rowType === 'projects' ? 'visible' : 'hidden' // Conditionally set visibility
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  }
                  data-reference={reference}
                  data-type="folder" // Add data-type attribute for folder nodes
                >
                  <Divider />
                  {renderTree(displayData, 'rooms')}
                </TreeItem>
              </>
            );
          }
        }
      });
  };
  //function to get shared projects from DB
  const getSharedProjects = async () => {
    const sharedProjects = doc(
      store,
      userStorage.sharedProjectsDesigner,
      auth.currentUser.email
    );
    const sharedProjectsData = (await getDoc(sharedProjects)).data();
    setSharedProjects(sharedProjectsData);
    setProjectsLoading(false);
  };
  //function to load projects from firebase storage
  const load = async () => {
    if (storageRef == null) return;
    getFiles(storageRef)
      .then((result) => {
        setProjectsLoading(true);
        setfs(result);
        setProjectList(Object.keys(result));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await getSharedProjects();

  };
  useEffect(() => {
    load();
  }, []);
  //function to add dynamic input fields for creating project
  const dynamicInputFields = () => {
    setroomCount(roomCount + 1);
    var ifield = inputFields;
    var label = roomCount;
    ifield.push(
      <Stack spacing={2} direction="row" justifyContent="center">
        <TextField
          required
          label="Enter Room Name"
          variant="standard"
          size="small"
          data-textfield={label}
          className="roomName"
          key={label}
        />
        <Button
          size="small"
          onClick={(e) => {
            const textField = document.querySelector(
              `[data-textfield="${label}"]`
            );
            if (textField) {
              e.target.parentNode.remove();
              e.target.parentNode.removeChild(e.target);
              textField.remove();
            }
          }}
          variant="standard"
        >
          <DeleteOutlineIcon fontSize="small" />
        </Button>
      </Stack>
    );
    setInputFields(ifield);
  };
  //function to create project
  const createProject = () => {
    var foldername = document.getElementById("projectName").value;
    var subfolderList = Array.from(document.getElementsByClassName("roomName"));
    // Helper function to show error messages
    const showMessage = (msg) => {
      setMessage(msg);
      handleSnackClick();
    };
    // Check for duplicates in subfolders
    var subFolderNames = subfolderList.map(
      (item) => item.getElementsByTagName("input")[0].value
    );
    const uniqueElements = new Set();
    const duplicates = [];
    if (foldername.length > 20 || foldername.length < 3) {
      showMessage("Project name should be less than 20 characters!");
      return;
    }
    if (fs.hasOwnProperty(foldername)) {
      showMessage("Project already exists!");
      return;
    }
    subFolderNames.forEach((item) => {
      if (uniqueElements.has(item)) {
        duplicates.push(item);
      } else {
        uniqueElements.add(item);
      }
    });
    if (duplicates.length > 0) {
      showMessage("Duplicate Room Names!");
      return;
    }
    // Check if folder name contains only alphabets
    if (!/^[a-zA-Z ]+$/.test(foldername)) {
      showMessage("Project name should contain only alphabets!");
      return;
    }
    // Check if folder name and subfolder list are not empty
    if (!foldername || subfolderList.length === 0) {
      showMessage("Please fill all required fields!");
      return;
    }
    for (let i = 0; i < subfolderList.length; i++) {
      const roomName = subfolderList[i].getElementsByTagName("input")[0].value;
      if (!roomName) {
        showMessage("Room name cannot be empty!");
        return;
      }
      if (roomName.length > 20 || roomName.length < 3) {
        showMessage("Room name should be between 3 to 20 characters!");
        return;
      }
      if (!/^[a-zA-Z ]+$/.test(roomName)) {
        showMessage("Room name should contain only alphabets!");
        return;
      }
    }
    for (let i = 0; i < subfolderList.length; i++) {
      var subfolderName =
        subfolderList[i].getElementsByTagName("input")[0].value;
      uploadFolder(storageRef, foldername + "/" + subfolderName, subfolderName);
    }
    load();
    showMessage("Project Created!");
    setAnchorE1(null);
    setroomCount(1);
    setInputFields([]);
  };

  const createNewProject = () => {
    var projNamePrefix = "New Project";
    var projectName = projNamePrefix;
    var projIdPrefix = "Project";
    var projectId = projIdPrefix;

    // Helper function to show error messages
    const showMessage = (msg) => {
      setMessage(msg);
      handleSnackClick();
    };
    
    if (projectName.length > 20 || projectName.length < 3) {
      showMessage("Project name should be between 3 and 20 characters!");
      return;
    }

    var pi = 1;
    while(fs.hasOwnProperty(projectId)){
      projectId = projIdPrefix + pi;
      pi++;
    }

    // Check if project name contains only alphabets
    uploadFolder(storageRef, projectId, projectName, 'project');

    load();
    setAnchorE1(null);
    setroomCount(1);
    setInputFields([]);
  };

  const createNewRoom = (projectReference) => {
    // Helper function to show error messages
    const showMessage = (msg) => {
      setMessage(msg);
      handleSnackClick();
    };
    
    getFiles(projectReference)
      .then((result) => {
        var roomName = "New Room";
        var roomIdPrefix = "Room";
        var roomId = roomIdPrefix;

        var ri = 1;
        while(result.hasOwnProperty(roomId)){
          roomId = roomIdPrefix + ri;
          ri++;
        }

        uploadFolder(projectReference, roomId, roomName, 'room');
        load();
        setAnchorE1(null);
        setroomCount(1);
        setInputFields([]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });    
  };

  //function to uplaod metadata to cloud
  async function updateMetadata(storage, reference, newKeyValuePairs) {
    // Construct a reference to the metadata JSON file
    const metadataRef = ref(storage, reference);
    try {
      const updatedMetadataString = JSON.stringify(newKeyValuePairs);
      await uploadString(metadataRef, updatedMetadataString, "raw", {
        contentType: "application/json",
      });

      setMessage("Metadata updated!");
      handleSnackClick();
    } catch (error) {
      setMessage("Error updating metadata!");
      handleSnackClick();
    }
  }

  const addRoom = () => {
    var roomName = document.getElementById("addRoomName").value;
    if (roomName.length > 20 || roomName.length < 3) {
      setMessage("Room name should be in between 3 to 20 characters!");
      handleSnackClick();
      return;
    }
    if (roomName.match(/^[a-zA-Z ]+$/) == null) {
      setMessage("Room name should contain only alphabets!");
      handleSnackClick();
      return;
    }

    //check if room exists in project
    if (roomName in fs[selectedRef.split("/").slice(-1)[0]]) {
      setMessage("Room already exists!");
      handleSnackClick();
      return;
    }
    var roomRef = ref(
      storage,
      selectedRef + "/" + roomName + "/" + roomName + "-metadata.json"
    );
    uploadString(roomRef, JSON.stringify({}), "raw", {
      contentType: "application/json",
    })
      .then(() => {
        setMessage("Room Created!");
        handleSnackClick();
        setAddRoomOpen(false);
        setAddRoomAnchorEl(null);
      })
      .catch((error) => {
        setMessage("Error creating room!");
        handleSnackClick();
      });
  };
  //audio playing code

  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioURL, setAudioURL] = useState(null);

  useEffect(() => {
    if (audioLoaded === true) {
      const audioElement = document.getElementById("audioPlayer");
      if (audioElement) {
        audioElement.play();
      }
    }
  }, [audioLoaded]);

  function playRef() {
    const storageRef = ref(storage, selectedRef);

    getDownloadURL(storageRef)
      .then((url) => {
        setAudioURL(url);
        setAudioLoaded(true);
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }

  const { confirm, dialog } = useConfirmDialog();

  return (
    <div className="manager" style={{ zIndex: 2 }} onLoadStart={load}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        style={{
          position: "fixed",
          top: "150px", // Move Snackbar downward
          left: "50%",
          transform: "translateX(-50%)",
        }}
        ContentProps={{
          style: {
            fontSize: "4rem", // Increase font size
            padding: "24px 32px", // Increase padding
            minWidth: "350px", // Increase width
            textAlign: "center", // Center the text if needed
          },
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="info"
          variant="filled"
          sx={{
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(255, 255, 255, 0.87)",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          }}
        >
          {message.toString()}
        </Alert>
      </Snackbar>
      <div className="nav_button">
        <Button
          variant="text"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={(theme) => ({
            ...(open && { display: "none" }),
            zIndex: 1102,
            height: "60vh",
            padding: "0px",
            marginLeft: "10px",
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            border: "1px solid",
            borderColor: "divider",
            color: theme.palette.mode === "dark" ? "white" : "black",
          })}
        >
          <ChevronRightIcon />
        </Button>
      </div>
      <Drawer
        sx={(theme) => ({
          ...(open && {
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            marginLeft: "10px",
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            borderRadius: "10px",
          }),
          zIndex: 1102,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            ...(open && {
              position: "relative",
              width: drawerWidth,
              boxSizing: "border-box",
              height: "60vh",
              alignSelf: "center",
              bgcolor: "rgb(0,0,0,0)",
              borderRadius: "10px",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              // color:
              //   theme.palette.mode === "dark"
              //     ? "#000"
              //     : theme.palette.primary.main,
            }),
            height: "60vh",
            position: "relative",
          },
        })}
        variant="persistent"
        ModalProps={{
          keepMounted: false,
        }}
        anchor="left"
        open={open}
      >
        {/* context menu popper for project browser nodes */}
        <Popper
          style={{ zIndex: 1201 }}
          id={MenuId}
          open={openMenu}
          anchorEl={anchorE2}
        >
          <ClickAwayListener onClickAway={() => setAnchorE2(null)}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                
              }}
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <Stack direction="column">
                <Typography
                  className="menu"
                  onClick={(event) => {
                    setSelectedProject(selectedRef.split("/").slice(-1)[0]);
                    setAnchorE2(null);
                    handleDesClick(event);
                  }}
                >
                  {selectedRef && selectedRef.split("/").length === 5
                    ? "Share Project"
                    : ""}
                </Typography>
                <Typography
                  className="menu"
                  onClick={(event) => {
                    setSelectedProject(selectedRef.split("/").slice(-1)[0]);
                    setAddRoomOpen(true);
                    setAddRoomAnchorEl(
                      document.querySelector(
                        `[data-reference="${selectedRef}"]`
                      )
                    );
                    setAnchorE2(null);
                  }}
                >
                  {selectedRef && selectedRef.split("/").length === 5
                    ? "Add Room"
                    : ""}
                </Typography>
                <Typography
                  className="menu"
                  onClick={() => {
                    playRef();
                    setAnchorE2(null);
                  }}
                >
                  {selectedRef && selectedRef.endsWith(".mp3") ? "Play" : ""}
                </Typography>
                {selectedRef ? (
                  <Typography
                    className="menu"
                    onClick={async () => {
                      setConfirmDialogText("Are you sure you want to delete?");
                      const result = await confirm();
                      if (!result) {
                        return;
                      }
                      deleteRef(selectedRef);
                      setAnchorE2(null);
                      load();
                    }}
                  >
                    Delete
                  </Typography>
                ) : null}
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* create popper for adding room to project */}
        <Popper
          style={{ zIndex: 1201 }}
          open={addRoomOpen}
          anchorEl={addRoomAnchorEl}
        >
          <ClickAwayListener
            onClickAway={() => {
              setAddRoomAnchorEl(false);
              setAddRoomOpen(false);
            }}
          >
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                
              }}
              component="form"
              noValidate
              autoComplete="off"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  setAddRoomAnchorEl(null);
                  setAddRoomOpen(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Add Room to '{selectedProject}'
                </h4>
                <TextField
                  required
                  label="Enter Room Name"
                  size="small"
                  variant="standard"
                  id="addRoomName"
                />
                <Stack spacing={2} direction="row">
                  <Button variant="contained" size="small" onClick={addRoom}>
                    <AddIcon fontSize="small" style={{ paddingRight: "5px" }} />
                    Add Room
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* share project to multiple customer popper */}
        <Popper
          style={{ zIndex: "1201" }}
          open={desOpen}
          anchorEl={desAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setDesAnchorEl(null)}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  setDesAnchorEl(null);
                  setEmailList([]);
                  setEmail("");
                  setDesOpen(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Share {selectedProject} with :
                </h4>
                <TextField
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label="Enter customer mail"
                  size="small"
                  variant="standard"
                />
                <List sx={{ width: "100%" }}>
                  {emailList.map((email, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          variant="standard"
                          onClick={() => handleDeleteEmail(email)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText id={index} primary={email} />
                    </ListItem>
                  ))}
                </List>

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleAddEmail();
                    }}
                  >
                    <AddIcon fontSize="small" style={{ paddingRight: "5px" }} />
                    Add email
                  </Button>
                  <Button
                    disabled={emailList.length === 0}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleDesSubmit();
                    }}
                  >
                    <DoneIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                    Ok
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* edit project popper to manage shared projects */}
        <Popper
          style={{ zIndex: 1201 }}
          open={customerOpen}
          anchorEl={customerAnchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={() => setCustomerAnchorEl(null)}>
            <Box
              sx={{
                p: 1,
                borderRadius: 2,
                
                backdropFilter: "blur(24px)",
                border: "1px solid",
                borderColor: "divider",
                
              }}
              // component="form"
              autoComplete="on"
              alignItems="center"
              //disable hit enter to submit form
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <CloseIcon
                fontSize="small"
                onClick={() => {
                  setCustomerAnchorEl(null);
                  setSelectedMail("");
                  setCustomerOpen(false);
                }}
              ></CloseIcon>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <h4 style={{ margin: "auto" }}>
                  Manage project access to {selectedMail} :
                </h4>
                <List sx={{ width: "100%" }}>
                  {projectList.map((projectName, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggleCheckBox(index)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(index) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={projectName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleCusSubmit();
                    }}
                  >
                    <DoneIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </ClickAwayListener>
        </Popper>
        {/* designer space tree view */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "50%",
          }}
        >
          <TreeView
            onClick={(event) => {
              var reference =
                event.target.parentElement.parentElement.getAttribute(
                  "data-reference"
                );
              setSelectedRef(reference);
            }}
            onContextMenu={(event) => handleMenuClick(event, "right-start")}
            onFocusCapture={(e) => e.stopPropagation()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            draggable
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            style={{ textAlign: "left" }}
            multiSelect
            //make elements inside spaced evenly
          >
            {/* Designer Space */}
            <TreeItem
              key={"Designer Space"}
              nodeId={"Designer Space"}
              label={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <span style={{ fontFamily: 'Audiowide, sans-serif' }}> Designer Space</span>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                      <CircularProgress size="15px" fontSize="small"
                        style={{
                          visibility: (!projectsLoading) ? 'visible' : 'hidden'
                        }}
                      />
                      <IconButton size="small">
                        <AddCircleOutlineIcon onClick={handleAddProject} fontSize="small"/>
                      </IconButton>
                      <IconButton size="small">
                        <DeleteOutlineIcon
                          fontSize="small"
                          onClick={handleNoOp}
                          style={{
                            visibility: 'hidden'
                          }} 
                        />
                      </IconButton>
                      <IconButton size="small">
                        <ChevronLeftIcon
                          fontSize="small"
                          onClick={handleDrawerClose}
                        />
                      </IconButton>
                    </div>
                </div>
              }
              data-reference={storageRef}
              data-type="folder" // Add data-type attribute for folder nodes
              icon={<ArchitectureIcon/>}
            >
            </TreeItem>
            <Divider 
              style={{
                width: '95%', // Adjust the width as needed
                margin: '0 auto', // Center the divider
              }}
            />
            {renderTree(fs, 'projects')}
          </TreeView>
          {/* shared space tree view */}
          <TreeView
            draggable
            onContextMenu={(event) => event.preventDefault()}
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            style={{ textAlign: "left" }}
          >
            <TreeItem
              key="Shared Space" // Unique key for the root TreeItem
              nodeId="Shared Space" // Unique nodeId for the root TreeItem
              label={
                <span style={{ fontFamily: "Audiowide, sans-serif" }}>
                  Shared Space
                </span>
              }
              collapseIcon={<GroupAddIcon color="disabled" />} // Icon for the root Tree
              expandIcon={<GroupAddIcon />} // Icon for the root Tree
              icon={<GroupAddIcon />} // Always show the icon
            >
            </TreeItem>
            <Divider 
              style={{
                width: '95%', // Adjust the width as needed
                margin: '0 auto', // Center the divider
              }}
            />
            {Object.entries(sharedProjects)
                .filter(([email, projects]) => projects.length > 0) // Filter out emails with no projects
                .map(([email, projects], index) => (
                  <TreeItem
                    key={index} // Unique key for each email TreeItem
                    nodeId={email} // Unique nodeId for each email TreeItem
                    label={email} // Label for each email TreeItem
                    onContextMenu={(event) => handleCusClick(event, email)} // Custom context menu handler
                  >
                    {projects.map((project, idx) => (
                      <TreeItem
                        key={idx} // Unique key for each project TreeItem
                        nodeId={`${email}-${idx}`} // Unique nodeId for each project TreeItem
                        label={project} // Label for each project TreeItem
                      />
                    ))}
                  </TreeItem>
                ))}
          </TreeView>
        </div>
        {dialog}
        {/* this is the audio player element */}
        {audioLoaded && (
          <audio src={audioURL} id="audioPlayer">
            Play ME
          </audio>
        )}
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
      </Main>
    </div>
  );
}
